import { GuestsObjects } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";

export interface RoomsInputProps {
  defaultValue?: GuestsObjects;
  onChange?: (data: GuestsObjects) => void;
  className?: string;
  roomAmount: number;
  setRoomAmount?: any;
}

const RoomsInput: FC<RoomsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
  roomAmount,
  setRoomAmount,
}) => {
  const [totalRooms, setTotalRooms] = useState<number>(1);

  useEffect(() => {
    setTotalRooms(roomAmount);
  }, [roomAmount]);

  const handleChangeData = (value: number) => {
    setRoomAmount(value);
    setTotalRooms(value);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`How Many?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={totalRooms}
        onChange={(value) => handleChangeData(value)}
        max={20}
        label="Rooms"
        desc="Select No of Rooms"
      />
    </div>
  );
};

export default RoomsInput;
