import { BookedRoomGuestDetails } from "data/types";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getAvailableRoomTypeList, getIpData, saveActivityLog } from "traverse";
import {
  GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE,
  IP_DETAILS_RESPONSE,
  SAVE_ACTIVITY_LOG_RESPONSE,
} from "traverse/response_body";
import GuestsInput from "../GuestsInput";
import RoomInput from "../RoomInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import {
  addSearchResult,
  clearSearchResult,
} from "../../../redux/slices/roomSearchResultSlice";
import { FormatDate } from "utils/converSelectedDateToString";
import {
  updateGuestSearchCriteria,
  updateRoomSearchCriteria,
  updateSearchStatus,
} from "../../../redux/slices/searchCriteriaSlice";
import {
  removeAllRoomType,
  updateTotal,
} from "../../../redux/slices/bookingSlice";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Button from "shared/Button/Button";
import { SAVE_ACTIVITY_LOG_REQUEST } from "traverse/request_body";
import { updateTransId } from "../../../redux/slices/activityLogSlice";

const StaySearchForm: FC<{}> = () => {
  const searchedCriteria = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  const orgDetails = useSelector((state: RootState) => state.org);
  const activityLogDetails = useSelector(
    (state: RootState) => state.activityLogSlice
  );
  const dispatch = useDispatch();

  const [guestAmount, setGuestAmount] = useState<BookedRoomGuestDetails[]>([]);
  const [roomAmount, setRoomAmount] = useState<number>(1);
  const [defaultSearchFunctionCalled, setDefaultSearchFunctionCalled] =
    useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);

  useEffect(() => {
    setGuestAmount([
      {
        guestTypeId: 1,
        amount: 1,
      },
    ]);
    // handleSearch(true);
  }, []);

  useEffect(() => {
    // if (!defaultSearchFunctionCalled) {
    if (
      guestAmount.length > 0 &&
      orgDetails.client.clientId > 0 &&
      orgDetails.orgId > 0
    ) {
      new Promise<IP_DETAILS_RESPONSE>((resolve) => {
        let _get_ip_details_promise = getIpData();
        resolve(_get_ip_details_promise);
      }).then((response) => {
        let extractedData = response;
        let addedData = { ...extractedData, device: navigator.userAgent };
        new Promise<SAVE_ACTIVITY_LOG_RESPONSE>((resolve) => {
          let _save_activity_log_promise = saveActivityLog({
            clientId: orgDetails.client.clientId,
            orgId: orgDetails.orgId,
            transId: "",
            taskName: "User Visit",
            description: JSON.stringify(addedData),
            createdUsername: "Sapro IBE",
            reservationId: 0,
            reservationLineId: 0,
            primaryKey: 0,
          });
          resolve(_save_activity_log_promise);
        }).then((response) => {
          if (!response.hasError) {
            dispatch(updateTransId(response.logTransId));
            handleSearch(true, response.logTransId);
            dispatch(removeAllRoomType());
            dispatch(updateTotal(0));
            setDefaultSearchFunctionCalled(true);
          }
        });
      });
    }
    // }
  }, [orgDetails]);

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total ? __total : 1;
  };

  const handleSearch = (default_load: boolean, trans_id: string = "") => {
    let _check_in_date: string;
    let _check_out_date: string;
    if (default_load) {
      const queryParams = new URLSearchParams(window.location.href);
      let urlExtractedCheckInDate = queryParams.get("check-in");
      if (urlExtractedCheckInDate !== null) {
        if (!urlExtractedCheckInDate.includes("-")) {
          let dateString = urlExtractedCheckInDate
            .replace("%2F", "/")
            .replace("%20", " ");

          // Current year
          var currentYear = new Date().getFullYear();

          // Construct date string in MM/DD/YYYY format
          var validDateString = dateString + "/" + currentYear;

          // Create a new Date object
          urlExtractedCheckInDate = validDateString;
        }
        _check_in_date = FormatDate(new Date(urlExtractedCheckInDate));
      }
      //
      let urlExtractedCheckOutDate = queryParams.get("check-out");
      if (urlExtractedCheckOutDate !== null) {
        if (!urlExtractedCheckOutDate.includes("-")) {
          let dateString = urlExtractedCheckOutDate
            .replace("%2F", "/")
            .replace("%20", " ");

          // Current year
          var currentYear = new Date().getFullYear();

          // Construct date string in MM/DD/YYYY format
          var validDateString = dateString + "/" + currentYear;

          // Create a new Date object
          urlExtractedCheckOutDate = validDateString;
        }
        _check_out_date = FormatDate(new Date(urlExtractedCheckOutDate));
      }
    } else {
      _check_in_date = FormatDate(searchedCriteria.checkInDate);
      _check_out_date = FormatDate(searchedCriteria.checkOutDate);
    }
    let guestTotalCount = calculateTotalGuests(
      guestAmount.map((guest_type) => guest_type.amount)
    );
    if (guestTotalCount >= roomAmount) {
      dispatch(updateSearchStatus(true));
      dispatch(clearSearchResult());
      new Promise<GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE>((resolve) => {
        let _room_search_result_promise = getAvailableRoomTypeList({
          clientId: orgDetails.client.clientId,
          orgId: orgDetails.orgId,
          checkInDate: _check_in_date,
          checkOutDate: _check_out_date,
          guestAmount: guestTotalCount,
          roomAmount: roomAmount,
          tourOperatorId: orgDetails.tourOperatorId,
          marketId: orgDetails.marketId,
        });
        resolve(_room_search_result_promise);
      }).then((response) => {
        if (!response.hasError) {
          // response
          dispatch(updateGuestSearchCriteria(guestAmount));
          dispatch(updateRoomSearchCriteria(roomAmount));
          dispatch(addSearchResult(response));
          dispatch(removeAllRoomType());
          dispatch(updateTotal(0));
        }
        dispatch(updateSearchStatus(false));
        let _activity_log_data = {
          checkInDate: _check_in_date,
          checkOutDate: _check_out_date,
          guestAmount: guestTotalCount,
          roomAmount: roomAmount,
          tourOperatorId: orgDetails.tourOperatorId,
          marketId: orgDetails.marketId,
        };
        new Promise<SAVE_ACTIVITY_LOG_RESPONSE>((resolve) => {
          let _save_activity_log_promise = saveActivityLog({
            clientId: orgDetails.client.clientId,
            orgId: orgDetails.orgId,
            transId: default_load ? trans_id : activityLogDetails.transId,
            taskName: "Room Search",
            description: JSON.stringify(_activity_log_data),
            createdUsername: "Sapro IBE",
            reservationId: 0,
            reservationLineId: 0,
            primaryKey: 0,
          });
          resolve(_save_activity_log_promise);
        }).then((response) => {
          if (!response.hasError) {
            dispatch(updateTransId(response.logTransId));
          }
        });
      });
    } else {
      setShowAlertMessage(true);
    }
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <StayDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          guestAmount={guestAmount}
          setGuestAmount={setGuestAmount}
          className="flex-1"
          hasButtonSubmit={false}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RoomInput
          className="flex-1"
          roomAmount={roomAmount}
          setRoomAmount={setRoomAmount}
          handleClick={() => handleSearch(false)}
        />

        <Transition appear show={showAlertMessage} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => setShowAlertMessage(false)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
              </Transition.Child>

              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block py-8">
                  <div className="inline-flex py-8 px-10 flex-col items-center w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                    <div className="w-16 h-16 border-2 border-orange-500 bg-orange-100 rounded-full flex justify-center items-center">
                      <i className="las la-exclamation text-3xl text-orange-500"></i>
                    </div>
                    <div className="font-medium text-2xl pb-2">Warning!</div>
                    <div className="text-lg pb-6">
                      Room Count must be less than Guest Count.
                    </div>
                    <Button
                      onClick={() => setShowAlertMessage(false)}
                      className={`bg-orange-200 p-0`}
                    >
                      Try Again
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
