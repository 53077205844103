import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { DEMO_GUEST_TYPE_LISTINGS } from "data/listings";
import { BookedRoomGuestDetails, GuestCategory } from "data/types";
import React, { useEffect, useState } from "react";
import { FC } from "react";
export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
  guestAmount: BookedRoomGuestDetails[] | [];
  setGuestAmount?: any;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
  guestAmount,
  setGuestAmount,
}) => {
  const [guestCategories, setGuestCategories] = useState<GuestCategory[]>();
  const [totalGuests, setTotalGuests] = useState<number>(0);
  const [guestModelOpened, setGuestModelOpened] = useState<boolean>(false);

  const handleChangeData = (value: number, type: GuestCategory) => {
    let __guest_category_item: BookedRoomGuestDetails = {
      guestTypeId: type.guestTypeId,
      amount: value,
    };
    let __index_of_category_item = guestAmount.findIndex(
      (guest_category_item) =>
        guest_category_item.guestTypeId === type.guestTypeId
    );
    if (__index_of_category_item === -1) {
      setGuestAmount((prev_item_list: BookedRoomGuestDetails[]) => [
        ...prev_item_list,
        __guest_category_item,
      ]);
    } else {
      setGuestAmount((guest_amount: BookedRoomGuestDetails[]) => {
        let prev_item_list = [...guest_amount]
        if (value === 0) {
          prev_item_list.splice(__index_of_category_item, 1);
        } else {
          prev_item_list[__index_of_category_item] = __guest_category_item;
        }
        let __total_amount_of_guests_list = prev_item_list.map(
          (guest_category_item) => guest_category_item.amount
        );
        let total = 0;
        __total_amount_of_guests_list.forEach((value) => (total += value));
        setTotalGuests(total);
        return prev_item_list;
      });
    }
  };

  useEffect(() => {
    setGuestCategories([...DEMO_GUEST_TYPE_LISTINGS]);
  }, []);

  useEffect(() => {
    if (guestModelOpened) {
      if (guestAmount.length > 0) {
        let __guest_category_list = [...DEMO_GUEST_TYPE_LISTINGS];
        guestAmount.forEach((guest_type_item) => {
          let ___guest_category_type_index = __guest_category_list.findIndex(
            (category_item) =>
              category_item.guestTypeId === guest_type_item.guestTypeId
          );
          __guest_category_list[___guest_category_type_index] = {
            ...__guest_category_list[___guest_category_type_index],
            defaultValue: guest_type_item.amount,
          };
        });
        setGuestCategories([...__guest_category_list]);
      } else {
        let __guest_category_list = [...DEMO_GUEST_TYPE_LISTINGS];
        __guest_category_list[0] = {
          ...__guest_category_list[0],
          defaultValue: 1,
        };
        setGuestCategories([...__guest_category_list]);
      }
    }
  }, [guestModelOpened]);

  useEffect(() => {
    let __total_amount_of_guests_list = guestAmount.map(
      (guest_category_item) => guest_category_item.amount
    );
    let total = 0;
    __total_amount_of_guests_list.forEach((value) => (total += value));
    setTotalGuests(total === 0 ? 1 : total);
  }, [guestAmount]);

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      {guestCategories?.map((guest_category, index) => (
        <NcInputNumber
          className={`w-full ${index > 0 ? "mt-6" : ""}`}
          defaultValue={guest_category.defaultValue}
          onChange={(value) => handleChangeData(value, guest_category)}
          max={10}
          min={0}
          label={guest_category.name}
          desc={guest_category.description}
        />
      ))}
    </div>
  );
};

export default GuestsInput;
