import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SearchRoomTypesForReservationResponse } from "data/types";
import { GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE } from "traverse/response_body";

const initialState: GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE = {
  hasError: false,
  errorMsg: "",
  availableRoomTypeList: [
    {
      roomTypeId: 0,
      ibeRoomTypeName: "",
      availableRooms: 0,
      maxOccupancy: 0,
      roomTypeImageList: [{ imageId: 0, imagePath: "", imageType: "" }],
      mealPlanRateList: [{ mealPlanId: 0, mealPlanName: "", rate: 0 }],
      amenityList: [
        {
          iconClassname: "",
          amenityName: "",
        },
      ],
    },
  ],
};

export const roomSearchResultSlice = createSlice({
  name: "room_search_result",
  initialState,
  reducers: {
    addSearchResult: (
      state,
      action: PayloadAction<GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE>
    ) => {
      state.hasError = action.payload.hasError;
      state.errorMsg = action.payload.errorMsg;
      state.availableRoomTypeList = action.payload.availableRoomTypeList;
    },
    clearSearchResult: (state) => {
      state.hasError = false;
      state.errorMsg = "";
      state.availableRoomTypeList = [
        {
          roomTypeId: 0,
          ibeRoomTypeName: "",
          availableRooms: 0,
          maxOccupancy: 0,
          roomTypeImageList: [{ imageId: 0, imagePath: "", imageType: "" }],
          mealPlanRateList: [{ mealPlanId: 0, mealPlanName: "", rate: 0 }],
          amenityList: [
            {
              iconClassname: "",
              amenityName: "",
            },
          ],
        },
      ];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSearchResult, clearSearchResult } =
  roomSearchResultSlice.actions;

export default roomSearchResultSlice.reducer;
