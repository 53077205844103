import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; // default: localStorage
import activityLogSlice from "./slices/activityLogSlice";
import bookingSlice from "./slices/bookingSlice";
import orgSlice from "./slices/orgSlice";
import roomSearchResultSlice from "./slices/roomSearchResultSlice";
import roomTypeSlice from "./slices/roomTypeSlice";
import searchCriteriaSlice from "./slices/searchCriteriaSlice";

const rootReducer = combineReducers({
  roomType: roomTypeSlice,
  booking: bookingSlice,
  org: orgSlice,
  roomSearchResult: roomSearchResultSlice,
  searchedCriteria: searchCriteriaSlice,
  activityLogSlice: activityLogSlice
});

// Create a persisted reducer
const persistedReducer = persistReducer(
  { key: 'root', storage },
  rootReducer
);

// Create the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor and export it
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
