import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketDetails, OrgDetails } from "data/types";

const initialState: OrgDetails = {
  address: "",
  city: "",
  client: { clientId: 0, name: "" },
  currency: { currencyId: 0, currencyCode: "" },
  description: "",
  email: "",
  hotelImageList: [{ imageId: 0, imagePath: "", imageType: "" }],
  languageCode: "",
  latitude: 0,
  longitude: 0,
  marketId: 0,
  name: "",
  notificationEmail: "",
  orgId: 0,
  phone: "",
  postalCode: "",
  roomTypeList: [
    {
      roomTypeId: 0,
      ibeRoomTypeId: "",
      name: "",
      description: "",
      allocatedRoomCount: 0,
      availableRoomsToday: 0,
      imageList: [{ imageId: 0, imagePath: "", imageType: "" }],
      maxOccupancy: 0,
      minimumRoomRateToday: 0,
    },
  ],
  marketList: [
    {
      marketId: 0,
      searchKey: "",
      marketCurrency: { currencyId: 0, currencyCode: "" },
    },
  ],
  socialMediaLinkList: [],
  timeZone: "",
  tourOperatorId: 0,
  orgDetailsExtracted: false,
};

export const orgSlice = createSlice({
  name: "org__details",
  initialState,
  reducers: {
    setOrgDetails: (state, action: PayloadAction<OrgDetails>) => {
      state.name = action.payload.name;
      state.client = action.payload.client;
      state.orgId = action.payload.orgId;
      state.tourOperatorId = action.payload.tourOperatorId;
      state.marketId = action.payload.marketId;
      state.description = action.payload.description;
      state.hotelImageList = action.payload.hotelImageList;
      state.marketList = action.payload.marketList;
      state.address = action.payload.address;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.roomTypeList = action.payload.roomTypeList;
      state.currency = action.payload.currency;
    },
    setOrgMarketAndCurrency: (state, action: PayloadAction<MarketDetails>) => {
      state.currency = action.payload.marketCurrency;
      state.marketId = action.payload.marketId;
    },
    updateOrgDetailExtractionStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.orgDetailsExtracted = action.payload;
    },
    clearOrgDetails: (state) => {
      state = {
        address: "",
        city: "",
        client: { clientId: 0, name: "" },
        currency: { currencyId: 0, currencyCode: "" },
        description: "",
        email: "",
        hotelImageList: [{ imageId: 0, imagePath: "", imageType: "" }],
        languageCode: "",
        latitude: 0,
        longitude: 0,
        marketId: 0,
        name: "",
        notificationEmail: "",
        orgId: 0,
        phone: "",
        postalCode: "",
        roomTypeList: [
          {
            roomTypeId: 0,
            ibeRoomTypeId: "",
            name: "",
            description: "",
            allocatedRoomCount: 0,
            availableRoomsToday: 0,
            imageList: [{ imageId: 0, imagePath: "", imageType: "" }],
            maxOccupancy: 0,
            minimumRoomRateToday: 0,
          },
        ],
        marketList: [
          {
            marketId: 0,
            searchKey: "",
            marketCurrency: { currencyId: 0, currencyCode: "" },
          },
        ],
        socialMediaLinkList: [],
        timeZone: "",
        tourOperatorId: 0,
        orgDetailsExtracted: false,
      };
    },
  },
});

export const { setOrgDetails, updateOrgDetailExtractionStatus, clearOrgDetails } =
  orgSlice.actions;

export default orgSlice.reducer;
