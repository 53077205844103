export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Example usage:
const date = new Date(); // Current date
const formattedDate = formatDate(date);
console.log(formattedDate); // Outputs: "yyyy-mm-dd"

export function getDateDifferenceInDays(date1: Date, date2: Date): number {
  // Convert both dates to UTC
  const utcDate1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const utcDate2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Calculate the difference in milliseconds
  const millisecondsDifference = utcDate2 - utcDate1;

  // Convert milliseconds to days
  return millisecondsDifference / (1000 * 60 * 60 * 24);
}

// Example usage:
const startDate = new Date("2023-01-01");
const endDate = new Date("2023-01-10");

const differenceInDays = getDateDifferenceInDays(startDate, endDate);
console.log(`Difference in days: ${differenceInDays}`);
