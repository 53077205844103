import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { imageServerURL } from "Config/Config";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-56",
}) => {
  const orgDetails = useSelector((state: RootState) => state.org);

  if(orgDetails.hotelImageList.length > 1){
    let __hotel_image_item = orgDetails.hotelImageList[1];
    let __hotel_image_item_w = orgDetails.hotelImageList[2];
    img = imageServerURL + __hotel_image_item.imagePath
    imgLight = imageServerURL + __hotel_image_item_w.imagePath
  }
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight /> */}
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
