import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "./type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { BookedRoomGuestDetails, GuestCategory } from "data/types";
import { DEMO_GUEST_TYPE_LISTINGS } from "data/listings";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
  guestAmount: BookedRoomGuestDetails[] | [];
  setGuestAmount?: any;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  guestAmount,
  setGuestAmount,
}) => {
  const [guestCategories, setGuestCategories] = useState<GuestCategory[]>();
  const [totalGuests, setTotalGuests] = useState<number>(0);
  const [guestModelOpened, setGuestModelOpened] = useState<boolean>(false);

  const handleChangeData = (value: number, type: GuestCategory) => {
    let __guest_category_item: BookedRoomGuestDetails = {
      guestTypeId: type.guestTypeId,
      amount: value,
    };
    let __index_of_category_item = guestAmount.findIndex(
      (guest_category_item) =>
        guest_category_item.guestTypeId === type.guestTypeId
    );
    if (__index_of_category_item === -1) {
      setGuestAmount((prev_item_list: BookedRoomGuestDetails[]) => [
        ...prev_item_list,
        __guest_category_item,
      ]);
    } else {
      setGuestAmount((guest_amount: BookedRoomGuestDetails[]) => {
        let prev_item_list = [...guest_amount]
        if (value === 0) {
          prev_item_list.splice(__index_of_category_item, 1);
        } else {
          prev_item_list[__index_of_category_item] = __guest_category_item;
        }
        let __total_amount_of_guests_list = prev_item_list.map(
          (guest_category_item) => guest_category_item.amount
        );
        let total = 0;
        __total_amount_of_guests_list.forEach((value) => (total += value));
        setTotalGuests(total);
        return prev_item_list;
      });
    }
  };

  useEffect(() => {
    setGuestCategories([...DEMO_GUEST_TYPE_LISTINGS]);
  }, []);

  useEffect(() => {
    if (guestModelOpened) {
      if (guestAmount.length > 0) {
        let __guest_category_list = [...DEMO_GUEST_TYPE_LISTINGS];
        guestAmount.forEach((guest_type_item) => {
          let ___guest_category_type_index = __guest_category_list.findIndex(
            (category_item) =>
              category_item.guestTypeId === guest_type_item.guestTypeId
          );
          __guest_category_list[___guest_category_type_index] = {
            ...__guest_category_list[___guest_category_type_index],
            defaultValue: guest_type_item.amount,
          };
        });
        setGuestCategories([...__guest_category_list]);
      } else {
        let __guest_category_list = [...DEMO_GUEST_TYPE_LISTINGS];
        __guest_category_list[0] = {
          ...__guest_category_list[0],
          defaultValue: 1,
        };
        setGuestCategories([...__guest_category_list]);
      }
    }
  }, [guestModelOpened]);

  useEffect(() => {
    let __total_amount_of_guests_list = guestAmount.map(
      (guest_category_item) => guest_category_item.amount
    );
    let total = 0;
    __total_amount_of_guests_list.forEach((value) => (total += value));
    setTotalGuests(total === 0 ? 1 : total);
  }, [guestAmount]);

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClick={() => setGuestModelOpened((prev_state) => !prev_state)}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {/* {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    setGuestInfantsInputValue(0);
                  }}
                />
              )} */}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} />
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              {guestCategories?.map((guest_category, index) => (
                <NcInputNumber
                  className={`w-full ${index > 0 ? "mt-6" : ""}`}
                  defaultValue={guest_category.defaultValue}
                  onChange={(value) => handleChangeData(value, guest_category)}
                  max={10}
                  min={0}
                  label={guest_category.name}
                  desc={guest_category.description}
                />
              ))}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
