import React, { FC, Fragment, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { RoomTypeCard, StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { Dialog, Transition } from "@headlessui/react";
import ListingStayDetailModal from "components/ListingDetailModal/listing-stay-detail/ListingStayDetailModal";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { imageServerURL } from "Config/Config";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export interface StayCardProps {
  className?: string;
  data: RoomTypeCard;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const {
    roomTypeId,
    ibeRoomTypeId,
    name,
    description,
    allocatedRoomCount,
    availableRoomsToday,
    imageList,
    maxOccupancy,
    minimumRoomRateToday,
  } = data;
  const orgDetails = useSelector((state: RootState) => state.org);

  let [isOpenModalStayRoomType, setIsOpenModalStayRoomType] = useState(false);

  function openModelStayRoomType() {
    setIsOpenModalStayRoomType(true);
  }

  function closeModalStayRoomType() {
    setIsOpenModalStayRoomType(false);
  }

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${roomTypeId}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={imageList.map(
            (image_item) => imageServerURL + image_item.imagePath
          )}
          href={"/"}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {availableRoomsToday} Rooms Available
          </span>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <p className="overflow-hidden line-clamp-7">{description}</p>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {minimumRoomRateToday > 0 ? (
            <span className="text-base font-semibold">
              {orgDetails.currency.currencyCode} {minimumRoomRateToday}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /night
                </span>
              )}
            </span>
          ) : null}
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  const renderOpenModelStayRoomType = () => {
    return (
      <Transition appear show={isOpenModalStayRoomType} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalStayRoomType}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="flex justify-end px-6 py-4 text-center">
                    {/* <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3> */}
                    <span className="left-3 top-3">
                      <ButtonClose onClick={closeModalStayRoomType} />
                    </span>
                  </div>
                  <ListingStayDetailModal id={data.roomTypeId} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div
      className={`${availableRoomsToday ? "" : "pointer-events-none opacity-50"} nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {/* <Link to={href}>{renderContent()}</Link> */}
      <div onClick={openModelStayRoomType}>{renderContent()}</div>
      {renderOpenModelStayRoomType()}
    </div>
  );
};

export default StayCard;
