import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RoomReservationBill, RoomType } from "data/types";

const initialState: RoomType[] = [
  {
    id: 0,
    name: "",
    roomRate: 0,
  },
];

export const roomTypeSlice = createSlice({
  name: "room__type",
  initialState,
  reducers: {
    addRoomTypeDetails: (state, action: PayloadAction<RoomType>) => {
      state = [...state, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addRoomTypeDetails } = roomTypeSlice.actions;

export default roomTypeSlice.reducer;
