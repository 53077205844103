import { FC, Fragment, useEffect, useState } from "react";

interface Props {
  price: number;
}

export const PriceComponent: React.FC<Props> = ({ price }) => {
  // Formatting price to include thousand separators
  let formattedPrice = "";
  if (price > 0) {
    formattedPrice = price.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return <span>{formattedPrice}</span>;
};
