import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BookedRoomGuestDetails,
  RoomReservationBill,
  RoomType,
  SearchedCriteria,
} from "data/types";
import { getDateDifferenceInDays } from "utils/dateUtils";

let currentDate = new Date();
let tommorrowDate = new Date();
tommorrowDate.setDate(currentDate.getDate() + 1);

const initialState: SearchedCriteria = {
  checkInDate: currentDate,
  checkOutDate: tommorrowDate,
  nights: 1,
  roomsAmount: 1,
  searchedGuestCriteria: [],
  searching: true
};

export const searchCriteriaSlice = createSlice({
  name: "searched__criteria",
  initialState,
  reducers: {
    addDateRange: (state, action: PayloadAction<[Date, Date]>) => {
      let startDate = action.payload[0];
      let endDate = action.payload[1];
      state.checkInDate = startDate;
      state.checkOutDate = endDate;
      state.nights = getDateDifferenceInDays(startDate, endDate);
    },
    updateGuestSearchCriteria: (
      state,
      action: PayloadAction<BookedRoomGuestDetails[]>
    ) => {
      state.searchedGuestCriteria = action.payload;
    },
    updateRoomSearchCriteria: (state, action: PayloadAction<number>) => {
      state.roomsAmount = action.payload;
    },
    updateSearchStatus: (state, action: PayloadAction<boolean>) => {
      state.searching = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addDateRange,
  updateGuestSearchCriteria,
  updateRoomSearchCriteria,
  updateSearchStatus,
} = searchCriteriaSlice.actions;

export default searchCriteriaSlice.reducer;
