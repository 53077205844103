import { Dialog, Tab, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { BookedRoom, RoomReservationBill } from "data/types";
import {
  calculateTotal,
  removeRoomType,
  updateReservationCode,
} from "../../redux/slices/bookingSlice";
import {
  SAVE_ACTIVITY_LOG_RESPONSE,
  SAVE_RESERVATION_RESPONSE,
} from "traverse/response_body";
import { saveActivityLog, saveIBEReservationEndpoint } from "traverse";
import { FormatDate } from "utils/converSelectedDateToString";
import React from "react";
import {
  GET_BOOKING_FORM_DP_DATA_RESPONSE,
  MINIFIED_COUNTRY_LIST,
  MINIFIED_TITLE_LIST,
} from "../../traverse/response_body";
import { getBookReservationFormData } from "../../traverse";
import { paymentGatewayDetails } from "../../Config/Config";
import { ROOM_TYPE_BOOKING_DETAIL } from "traverse/request_body";
import { isUndefined } from "lodash";
import BookingSummaryCard from "components/BookingSummaryCard/BookingSummaryCard";
import Button from "shared/Button/Button";

export interface BookingDetailsFormPageProps {
  className?: string;
}

const BookingDetailsFormPage: FC<BookingDetailsFormPageProps> = ({
  className = "",
}) => {
  const orgDetails = useSelector((state: RootState) => state.org);
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const activityLogDetails = useSelector(
    (state: RootState) => state.activityLogSlice
  );
  const dispatch = useDispatch();

  const [dpTitleList, setDPTitleList] = useState<MINIFIED_TITLE_LIST[]>([
    { titleId: 0, titleName: "- Select -" },
  ]);
  const [dpCountryList, setDPCountryList] = useState<MINIFIED_COUNTRY_LIST[]>([
    { countryId: 0, countryCode: "", countryName: "- Select -" },
  ]);
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formDataLoading, setFormDataLoading] = useState<boolean>(true);

  const removeRoomTypeFromBill = (room_type_item: BookedRoom) => {
    dispatch(removeRoomType(room_type_item));
    dispatch(calculateTotal());
  };

  useEffect(() => {
    // dispatch(calculateTotal());
    new Promise<GET_BOOKING_FORM_DP_DATA_RESPONSE>((resolve) => {
      let _get_book_reservation_form_data_promise = getBookReservationFormData({
        clientId: orgDetails.client.clientId,
        orgId: orgDetails.orgId,
      });
      resolve(_get_book_reservation_form_data_promise);
    }).then((response) => {
      if (!response.hasError) {
        setDPTitleList([
          { titleId: 0, titleName: "- Select -" },
          ...response.titleList,
        ]);
        setDPCountryList([
          { countryId: 0, countryCode: "", countryName: "- Select -" },
          ...response.countryList,
        ]);
      }
      setFormDataLoading(false);
    });

    // bookingDetails.roomTypeList.forEach(room_type => {
    //   new Promise<GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE>((resolve) => {
    //     let _total_guests = calculateTotalGuests(
    //       room_type.guests.map((guest_type) => guest_type.amount)
    //     );
    //     let _get_room_type_details_promise =
    //       getRoomTypeRoomRateDetailsEndpoint({
    //         clientId: orgDetails.client.clientId,
    //         orgId: orgDetails.orgId,
    //         roomTypeId: room_type.roomId as number,
    //         checkInDate: FormatDate(bookingDetails.checkInDate),
    //         guestAmount: _total_guests ? _total_guests : 1,
    //         mealPlanId: room_type.mealPlanId,
    //         tourOperatorId: orgDetails.tourOperatorId,
    //         marketId: orgDetails.marketId,
    //       });
    //     resolve(_get_room_type_details_promise);
    //   }).then((response) => {
    //     dispatch(updateRoomRate({roomTypeId: room_type.roomId as number, roomRate: response.roomRate}))
    //     console.log("[DEBUG] useEffect.response: ", response);
    //   })
    // })
  }, []);

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total;
  };

  const renderSidebar = (
    booking_details: RoomReservationBill,
    removeRoomTypeFromBill: (room_type_item: BookedRoom) => void,
    calculateTotalGuests: (guest_list: number[]) => number
  ) => {
    return (
      // <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
      //   <div className="flex flex-col space-y-4">
      //     <div className="flex justify-between font-semibold">
      //       <div className="flex">
      //         <BanknotesIcon className="w-5 h-5 text-green-600 mr-1" />
      //         <span>Total</span>
      //       </div>
      //       <span>
      //         {orgDetails.currency.currencyCode}{" "}
      //         <PriceComponent price={booking_details.total as number} />
      //       </span>
      //     </div>
      //     <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      //   </div>
      //   {booking_details.roomTypeList.map((room_type_item) => (
      //     <div className="w-full flex flex-col sm:flex-row sm:items-center">
      //       <div className="w-full py-5 space-y-3">
      //         <div className="flex justify-between items-center">
      //           <div>
      //             <span className="text-sm text-neutral-500 dark:text-neutral-400">
      //               {orgDetails.currency.currencyCode}{" "}
      //               <PriceComponent price={room_type_item.roomRate as number} />
      //               <span className="ml-1">per night</span>
      //             </span>
      //             {/* <Link to={`/listing-stay-detail/${room_type_item.roomId}`}> */}
      //             <span className="text-base font-medium block">
      //               {room_type_item.roomName}
      //             </span>
      //             {/* </Link> */}
      //           </div>
      //           <div className="border p-2 rounded-lg border-2 border-red-300 cursor-pointer">
      //             <TrashIcon
      //               className="w-4 text-sm text-red-600"
      //               onClick={() => removeRoomTypeFromBill(room_type_item)}
      //             />
      //           </div>
      //         </div>
      //         <span className="block text-sm text-neutral-500 dark:text-neutral-400">
      //           {room_type_item.roomAmount} rooms ·{" "}
      //           {calculateTotalGuests(
      //             room_type_item.guests.map((guest_type) => guest_type.amount)
      //           )}{" "}
      //           guests · {booking_details.nights} nights
      //         </span>
      //         <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
      //         <div className="flex justify-between">
      //           <div className="text-sm text-neutral-500 font-semibold">
      //             {orgDetails.currency.currencyCode}{" "}
      //             <PriceComponent
      //               price={
      //                 (room_type_item.roomRate *
      //                   booking_details.nights) as number
      //               }
      //             />
      //           </div>
      //           {/* <StartRating /> */}
      //         </div>
      //       </div>
      //     </div>
      //   ))}
      // </div>
      <BookingSummaryCard showProceedButton={false} />
    );
  };

  const handleBookingSubmission = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const bookingDetailForm = new FormData(event.currentTarget);
    /**
     * Extracting Guest Details
     */
    let _first_name = "";
    if (bookingDetailForm.get("first_name") !== null) {
      _first_name = bookingDetailForm.get("first_name") as string;
    }
    let _last_name = "";
    if (bookingDetailForm.get("last_name") !== null) {
      _last_name = bookingDetailForm.get("last_name") as string;
    }
    let _title_id = bookingDetailForm.get("title_id") as string;
    let _nationality_id = 0;
    let _email = "";
    if (bookingDetailForm.get("email") !== null) {
      _email = bookingDetailForm.get("email") as string;
    }
    let _phone_number = "";
    if (bookingDetailForm.get("phone_no") !== null) {
      _phone_number = bookingDetailForm.get("phone_no") as string;
    }
    let _address_line_1 = "";
    if (bookingDetailForm.get("address_line_1") !== null) {
      _address_line_1 = bookingDetailForm.get("address_line_1") as string;
    }
    let _address_line_2 = "";
    if (bookingDetailForm.get("address_line_2") !== null) {
      _address_line_2 = bookingDetailForm.get("address_line_2") as string;
    }
    let _city = "";
    if (bookingDetailForm.get("city") !== null) {
      _city = bookingDetailForm.get("city") as string;
    }
    let _postal_code = "";
    if (bookingDetailForm.get("postal_code") !== null) {
      _postal_code = bookingDetailForm.get("postal_code") as string;
    }
    let _country_id = bookingDetailForm.get("country_id") as string;
    let _country = dpCountryList.find(
      (country_item) => country_item.countryId === parseInt(_country_id)
    );
    let _country_code = "";
    if (!isUndefined(_country)) {
      if (_country.countryCode !== null) {
        _country_code = _country.countryCode.split("/")[0].trim();
      }
    }
    /**
     * Applying Validations
     */
    // const nicExpression = /\b[0-9]{12}\b|\b[0-9]{9}[vV]\b|\b[0-9]{9}[xX]\b/;
    let _nicNo =
      bookingDetailForm.get("nic_no") === null
        ? ""
        : (bookingDetailForm.get("nic_no") as string);
    // if (_nicNo.match(nicExpression)) {
    let _country_validation_success = true;
    if (orgDetails.currency.currencyCode === "LKR") {
      if (_country_code !== "LK") {
        _country_validation_success = false;
      }
    }
    if (_country_validation_success) {
      setDisableContinueButton(true);
      new Promise<SAVE_RESERVATION_RESPONSE>((resolve) => {
        /**
         * Extracting Room Details
         */
        let _bookedRoomTypeList: ROOM_TYPE_BOOKING_DETAIL[] = [];
        let _booked_rooms = bookingDetails.roomTypeList;
        _booked_rooms.forEach((booked_room_type) => {
          let __adult_count = booked_room_type.guests.filter(
            (guest_item) => guest_item.guestTypeId == 1
          )[0]?.amount;
          let __complimentary_child_count = booked_room_type.guests.filter(
            (guest_item) => guest_item.guestTypeId == 4
          )[0]?.amount;
          let __chargeable_child_count = booked_room_type.guests.filter(
            (guest_item) => guest_item.guestTypeId == 2
          )[0]?.amount;
          let __child_6_to_12_count = booked_room_type.guests.filter(
            (guest_item) => guest_item.guestTypeId == 3
          )[0]?.amount;

          _bookedRoomTypeList.push({
            roomTypeId: booked_room_type.roomId as number,
            roomCount: booked_room_type.roomAmount,
            mealPlanId: booked_room_type.mealPlanId,
            guestDetails: {
              adultCount: __adult_count ? __adult_count : 0,
              complimentaryChildCount: __complimentary_child_count
                ? __complimentary_child_count
                : 0,
              chargeableChildCount: __chargeable_child_count
                ? __chargeable_child_count
                : 0,
              child6To12Count: __child_6_to_12_count
                ? __child_6_to_12_count
                : 0,
            },
          });
        });

        let _save_booked_reservation_promise = saveIBEReservationEndpoint({
          clientId: orgDetails.client.clientId,
          orgId: orgDetails.orgId,
          tourOperatorId: orgDetails.tourOperatorId,
          marketId: orgDetails.marketId,
          firstName: _first_name,
          lastName: _last_name,
          titleId: parseInt(_title_id),
          nationalityId: _nationality_id,
          email: _email,
          phoneNumber: _phone_number,
          addressLine1: _address_line_1,
          addressLine2: _address_line_2,
          city: _city,
          countryId: parseInt(_country_id),
          postalCode: _postal_code,
          checkInDate: FormatDate(new Date(bookingDetails.checkInDate)),
          checkOutDate: FormatDate(new Date(bookingDetails.checkOutDate)),
          isPaid: false,
          currencyId: orgDetails.currency.currencyId,
          nicNo: _nicNo,
          bookedRoomTypeList: _bookedRoomTypeList,
        });
        resolve(_save_booked_reservation_promise);
      }).then((response) => {
        if (response.isSuccess) {
          let _reservation_id = response.reservationId;
          let _reservation_code = response.reservationCode;
          dispatch(updateReservationCode(_reservation_code));

          // let __payment_details = new FormData();
          let __signed_fields =
            "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_to_email,bill_to_forename,bill_to_surname,bill_to_address_line1,bill_to_address_city,bill_to_address_country";
          // __payment_details.append("access_key", orgDetails.currency.currencyCode ==="LKR" ? paymentGatewayDetails.local.accessKey : paymentGatewayDetails.foriegn.accessKey);
          // __payment_details.append("profile_id", orgDetails.currency.currencyCode ==="LKR" ? paymentGatewayDetails.local.profileId : paymentGatewayDetails.foriegn.profileId);
          // __payment_details.append("transaction_uuid", "65af639ba463e");
          // __payment_details.append("signed_field_names", __signed_fields);
          // __payment_details.append("unsigned_field_names", "");
          // __payment_details.append("signed_date_time", "2024-01-23T06:58:35Z");
          // __payment_details.append("locale", "en");
          // __payment_details.append("transaction_type", "sale");
          // __payment_details.append("reference_number", new Date().getTime() + "");
          // __payment_details.append("amount", bookingDetails.total + "");
          // __payment_details.append("currency", orgDetails.currency.currencyCode);
          // __payment_details.append("submit", "Submit");
          // __payment_details.append("bill_to_email", _email);
          // __payment_details.append("bill_to_address_line1", _address_line_1);
          // __payment_details.append("bill_to_address_city", _city);
          // __payment_details.append("bill_to_address_country", _country_code);
          // __payment_details.append("bill_to_forename", _first_name);
          // __payment_details.append("bill_to_surname", _last_name);

          // new Promise<string>((resolve) => {
          //   resolve(sign(__payment_details));
          // }).then((response) => {
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString(); // This will return the date in "YYYY-MM-DDTHH:mm:ss.sssZ" format

          const formattedDateWithoutMilliseconds =
            formattedDate.slice(0, -5) + "Z";

          let __queryString = "?";
          __queryString += `access_key=${
            orgDetails.currency.currencyCode === "LKR"
              ? paymentGatewayDetails.local.accessKey
              : paymentGatewayDetails.foriegn.accessKey
          }`;
          __queryString += `&profile_id=${
            orgDetails.currency.currencyCode === "LKR"
              ? paymentGatewayDetails.local.profileId
              : paymentGatewayDetails.foriegn.profileId
          }`;
          __queryString +=
            "&transaction_uuid=" + orgDetails.orgId + "_" + _reservation_code;
          __queryString += "&signed_field_names=" + __signed_fields;
          __queryString += "&unsigned_field_names=";
          __queryString +=
            "&signed_date_time=" + formattedDateWithoutMilliseconds;
          __queryString += "&locale=" + "en";
          __queryString += "&transaction_type=" + "sale";
          __queryString += "&reference_number=" + new Date().getTime() + "";
          __queryString += "&amount=" + bookingDetails.total;
          __queryString += "&currency=" + orgDetails.currency.currencyCode;
          __queryString += "&submit=" + "Submit";
          __queryString += "&bill_to_email=" + _email;
          __queryString += "&bill_to_address_line1=" + _address_line_1;
          __queryString += "&bill_to_address_city=" + _city;
          __queryString += "&bill_to_address_country=" + _country_code;
          __queryString += "&bill_to_forename=" + _first_name;
          __queryString += "&bill_to_surname=" + _last_name;

          new Promise<SAVE_ACTIVITY_LOG_RESPONSE>((resolve) => {
            let _save_activity_log_promise = saveActivityLog({
              clientId: orgDetails.client.clientId,
              orgId: orgDetails.orgId,
              transId: activityLogDetails.transId,
              taskName: "Temporary Reservation Saved",
              description: __queryString,
              createdUsername: "Sapro IBE",
              reservationId: _reservation_id,
              reservationLineId: 0,
              primaryKey: 0,
            });
            resolve(_save_activity_log_promise);
          }).then((response) => {
            window.location.href = `${paymentGatewayDetails.url}${__queryString}`;
          });

          // window.open(
          //   `${paymentGatewayDetails.url}${__queryString}`,
          //   "_blank"
          // );
          // open(`${paymentGatewayDetails.url}${__queryString}`, "_blank");
          // setTimeout(() => {
          // window.open(
          //   `${paymentGatewayDetails.url}${__queryString}`,
          //   "_blank"
          // );
          // }, 6000);

          // });
        }
      });
    } else {
      setShowAlertMessage(true);
      setErrorMessage("You were detected as a Non-Resident Guest");
    }
    // } else {
    //   setShowAlertMessage(true);
    //   setErrorMessage("Please provide a Valid NIC No.");
    // }
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Booking Details</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div className="mt-6 relative">
            <form
              method="POST"
              className={`${
                formDataLoading ? "pointer-events-none opacity-5" : ""
              }`}
              onSubmit={(evt) => handleBookingSubmission(evt)}
            >
              <Tab.Group>
                <Tab.Panels>
                  <Tab.Panel className="space-y-5">
                    <div className="space-y-1">
                      <Label className="required-input-mark">Title</Label>
                      <select name="title_id" required>
                        {dpTitleList.map((dp_title_item, index) => (
                          <option key={index} value={dp_title_item.titleId}>
                            {dp_title_item.titleName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <Label className="required-input-mark">
                          First Name{" "}
                        </Label>
                        <Input
                          placeholder="Enter First Name"
                          name="first_name"
                          required
                        />
                      </div>
                      <div className="space-y-1">
                        <Label className="required-input-mark">
                          Last Name{" "}
                        </Label>
                        <Input
                          placeholder="Enter Last Name"
                          name="last_name"
                          required
                        />
                      </div>
                    </div>
                    {/* {orgDetails.currency.currencyCode === "LKR" ? (
                      <div className="space-y-1">
                        <Label className="required-input-mark">NIC No.</Label>
                        <Input
                          placeholder="Enter National Identity Card No."
                          name="nic_no"
                          required
                        />
                      </div>
                    ) : null} */}
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
                    {/* <div className="space-y-1">
                        <Label>Nationality</Label>
                        <select name="nationality_id">
                          <option>- Select -</option>
                          <option>Sri Lankan</option>
                          <option>Indian</option>
                        </select>
                      </div> */}
                    {/* </div> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <Label className="required-input-mark">Email </Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="example@gmail.com"
                          required
                        />
                      </div>
                      <div className="space-y-1">
                        <Label>Phone</Label>
                        <Input placeholder="777 777 777" name="phone_no" />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <Label className="required-input-mark">
                        Address Line 1
                      </Label>
                      <Input
                        placeholder="Enter Address Line 1"
                        name="address_line_1"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>Address Line 2</Label>
                      <Input
                        placeholder="Enter Address Line 2"
                        name="address_line_2"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <Label className="required-input-mark">City</Label>
                        <Input placeholder="Enter City" name="city" required />
                      </div>
                      <div className="space-y-1">
                        <Label className="required-input-mark">Country</Label>
                        <select name="country_id" required>
                          {dpCountryList.map((dp_country_item, index) => (
                            <option
                              key={index}
                              value={dp_country_item.countryId}
                            >
                              {dp_country_item.countryName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <Label>Postal Code</Label>
                      <Input
                        placeholder="Enter Postal Code"
                        name="postal_code"
                      />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
              <div className="pt-8">
                <ButtonPrimary
                  className="w-full md:w-auto"
                  type="submit"
                  disabled={disableContinueButton}
                >
                  {disableContinueButton ? (
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-100"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <div className="ml-2">Please Wait...</div>
                    </div>
                  ) : (
                    "Continue"
                  )}
                </ButtonPrimary>
              </div>
            </form>
            {formDataLoading ? (
              <div className="flex flex-col justify-center items-center absolute top-0 mx-auto w-full h-full">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-200 animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <div className="text-green-500 text-lg">
                  Loading Form Data...
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 mt-10 md:mt-0">
          {renderMain()}
        </div>
        <div className="lg:block flex-grow">
          {renderSidebar(
            bookingDetails,
            removeRoomTypeFromBill,
            calculateTotalGuests
          )}
        </div>
      </main>
      <Transition appear show={showAlertMessage} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setShowAlertMessage(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8">
                <div className="inline-flex py-8 px-10 flex-col items-center w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="w-16 h-16 border-2 border-orange-500 bg-orange-100 rounded-full flex justify-center items-center">
                    <i className="las la-exclamation text-3xl text-orange-500"></i>
                  </div>
                  <div className="font-medium text-2xl pb-2">Warning!</div>
                  <div className="text-lg pb-6">{errorMessage}</div>
                  <Button
                    onClick={() => setShowAlertMessage(false)}
                    className={`bg-orange-200 p-0 ${
                      bookingDetails.total
                        ? ""
                        : "pointer-events-none opacity-50"
                    }`}
                  >
                    Try Again
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default BookingDetailsFormPage;
