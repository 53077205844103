import { FC, Fragment, useEffect, useState } from "react";
import SectionDateRange from "containers/ListingDetailPage/SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import {
  BookedRoom,
  BookedRoomGuestDetails,
} from "data/types";
import RoomsInput from "./RoomsInput";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { addRoomType, updateTotal } from "../../../redux/slices/bookingSlice";
import { StayDetailPageContainerProps } from "./types";
import {
  getMoreRoomTypeDetailsEndpoint,
  getRoomTypeRoomRateDetailsEndpoint,
} from "traverse";
import {
  GET_MORE_ROOM_TYPE_DETAILS_RESPONSE,
  GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE,
} from "traverse/response_body";
import { imageServerURL } from "../../../Config/Config";
import { FormatDate } from "utils/converSelectedDateToString";
import { PriceComponent } from "utils/PriceComponent";

const StayDetailPageContainer: FC<StayDetailPageContainerProps> = ({
  room_type_id,
}) => {
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const orgDetails = useSelector((state: RootState) => state.org);
  const dispatch = useDispatch();

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  let room_id = room_type_id;

  const [roomTypeDetails, setRoomTypeDetails] =
    useState<GET_MORE_ROOM_TYPE_DETAILS_RESPONSE | null>(null);
  const [roomTypeRoomRateDetails, setRoomTypeRoomRateDetails] =
    useState<GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE | null>(null);
  let [isOpenModalAmenities, setIsOpenModalAmenities] =
    useState<boolean>(false);
  const [billingRoomType, setBillingRoomType] = useState<BookedRoom>({
    roomId: 0,
    mealPlanId: 0,
    mealPlanName: "",
    roomName: "",
    roomAmount: 0,
    roomRate: 0,
    guests: [],
  });
  const [roomAmount, setRoomAmount] = useState<number>(1);
  const [guestAmount, setGuestAmount] = useState<BookedRoomGuestDetails[]>([]);
  const [roomReserved, setRoomReserved] = useState<boolean>(false);
  const [currentMealPlan, setCurrentmealPlan] = useState<number>(0);
  const [disabledDateList, setDisabledDateList] = useState<Date[]>();

  useEffect(() => {
    new Promise<GET_MORE_ROOM_TYPE_DETAILS_RESPONSE>((resolve) => {
      let _get_room_type_details_promise = getMoreRoomTypeDetailsEndpoint({
        clientId: orgDetails.client.clientId,
        orgId: orgDetails.orgId,
        roomTypeId: room_type_id,
        checkInDate: FormatDate(new Date()),
      });
      resolve(_get_room_type_details_promise);
    }).then((response) => {
      setRoomTypeDetails(response);
      if (response !== null) {
        if (response.roomTypeDetails !== null) {
          setBillingRoomType({
            ...billingRoomType,
            roomId: response.roomTypeDetails.roomTypeId as number,
            roomName: response.roomTypeDetails?.roomTypeName as string,
          });
          if (response.roomTypeDetails.roomMealPlanList !== null) {
            if (response.roomTypeDetails.roomMealPlanList.length > 0) {
              setCurrentmealPlan(
                response.roomTypeDetails.roomMealPlanList[0].mealPlanId
              );
            }
          }
          if (
            response.roomTypeDetails.roomTypeAvailabilityForTwoMonths !== null
          ) {
            if (
              response.roomTypeDetails.roomTypeAvailabilityForTwoMonths.length >
              0
            ) {
              let __room_type_unavailable_date_list = new Array<Date>();
              response.roomTypeDetails.roomTypeAvailabilityForTwoMonths.forEach(
                (room_type_month_availability) => {
                  let __object_key_list = Object.keys(
                    room_type_month_availability
                  );
                  let __object_value_list = Object.values(
                    room_type_month_availability
                  );
                  __object_value_list.forEach((object_value, index) => {
                    if (object_value === 0) {
                      if (__object_key_list[index].startsWith("date")) {
                        let __date_value = parseInt(
                          __object_key_list[index].substring(4)
                        );
                        let __date_string = `${room_type_month_availability.contractYear}-${room_type_month_availability.contractMonth}-${__date_value}`;
                        __room_type_unavailable_date_list.push(
                          new Date(__date_string)
                        );
                      }
                    }
                  });
                }
              );
              setDisabledDateList(__room_type_unavailable_date_list);
            }
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (roomTypeDetails !== undefined) {
      if (roomTypeDetails?.roomTypeDetails !== undefined) {
        if (roomTypeDetails?.roomTypeDetails?.guests !== undefined) {
          setGuestAmount([
            {
              guestTypeId: 1,
              amount: roomTypeDetails?.roomTypeDetails?.guests as number,
            },
          ]);
        }
      }
    }
  }, [roomTypeDetails]);

  // useEffect(() => {
  //   if (roomTypeDetails !== null) {
  //     if (roomTypeDetails.roomTypeDetails !== null) {
  //       if (roomTypeDetails.roomTypeDetails.roomTypeId > 0) {
  //         new Promise<GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE>((resolve) => {
  //           let _total_guests = calculateTotalGuests(
  //             guestAmount.map((guest_type) => guest_type.amount)
  //           );
  //           let _get_room_type_details_promise =
  //             getRoomTypeRoomRateDetailsEndpoint({
  //               clientId: orgDetails.client.clientId,
  //               orgId: orgDetails.orgId,
  //               roomTypeId: room_type_id,
  //               checkInDate: FormatDate(bookingDetails.checkInDate),
  //               guestAmount: _total_guests
  //                 ? _total_guests
  //                 : (roomTypeDetails?.roomTypeDetails?.guests as number),
  //               mealPlanId: currentMealPlan,
  //               tourOperatorId: orgDetails.tourOperatorId,
  //               marketId: orgDetails.marketId,
  //             });
  //           resolve(_get_room_type_details_promise);
  //         }).then((response) => {
  //           setRoomTypeRoomRateDetails(response);          
  //           setBillingRoomType({
  //             ...billingRoomType,
  //             guests: guestAmount,
  //             mealPlanId: currentMealPlan,
  //             roomRate: response.roomRate as number,
  //           });
  //         });
  //       }
  //     }
  //   }
  // }, [roomTypeDetails, currentMealPlan, guestAmount]);

  useEffect(() => {
    /**
     * Handling On Load[Event] Room Type Details Rendring Process
     */
    const __roomTypeList = bookingDetails.roomTypeList.find(
      (_) => _.roomId === room_id
    );
    // let __roomAmount: number = 1;
    // let __guests: any = [];
    // let __roomReserved: boolean = false;
    //   __roomAmount = __roomTypeList.roomAmount;
    //   __guests = __roomTypeList.guests;
    //   __roomReserved = true;
    // }

    // if (__roomTypeList !== undefined) {
    //   setBillingRoomType((prev) => ({
    //     ...prev,
    //     roomAmount: __roomTypeList.roomAmount,
    //     guests: __roomTypeList.guests,
    //   }));
    //   setRoomAmount(__roomTypeList.roomAmount);
    //   setGuestAmount(__roomTypeList.guests);
    //   setRoomReserved(true);
    // }
  }, [room_id]);

  useEffect(() => {
    setBillingRoomType((prev) => ({ ...prev, roomAmount: roomAmount }));
  }, [roomAmount]);

  useEffect(() => {
    console.log("[DEBUG] guestAmount: ", guestAmount);
    setBillingRoomType((prev) => ({ ...prev, guests: guestAmount }));
  }, [guestAmount]);
  
  useEffect(() => {
    console.log("[DEBUG] billingRoomType: ", billingRoomType);
  }, [billingRoomType])

  const handleReserve = () => {
    let _billing_room_type = billingRoomType;
    if (_billing_room_type.roomAmount < 1) {
      _billing_room_type.roomAmount = 1;
    }
    if (_billing_room_type.guests.length < 1) {
      _billing_room_type.guests = [{ guestTypeId: 1, amount: 1 }];
    }
    dispatch(addRoomType(_billing_room_type));
    if (roomTypeRoomRateDetails?.roomRate !== undefined) {
      dispatch(
        updateTotal(
          roomTypeRoomRateDetails?.roomRate * bookingDetails.nights * roomAmount
        )
      );
    }
    router("/book-now");
  };

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge name="1 Available" color="green" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {roomTypeDetails?.roomTypeDetails?.roomTypeName}
        </h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{roomTypeDetails?.roomTypeDetails?.description}</span>
        </div>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {roomTypeDetails?.roomTypeDetails?.guests}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">d
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div> */}
          {/* <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">rooms</span>
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {roomTypeDetails !== null
            ? roomTypeDetails?.roomTypeDetails !== null
              ? roomTypeDetails?.roomTypeDetails?.amenityList !== null
                ? roomTypeDetails.roomTypeDetails.amenityList
                    .filter((_, i) => i < 12)
                    .map((item) => (
                      <div
                        key={item.amenityName}
                        className="flex items-center space-x-3"
                      >
                        <i className={`text-3xl las ${item.iconClassname}`}></i>
                        <span className=" ">{item.amenityName}</span>
                      </div>
                    ))
                : null
              : null
            : null}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          {/* <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary> */}
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {roomTypeDetails !== null
                      ? roomTypeDetails?.roomTypeDetails !== null
                        ? roomTypeDetails?.roomTypeDetails?.amenityList !== null
                          ? roomTypeDetails.roomTypeDetails.amenityList
                              .filter((_, i) => i < 12)
                              .map((item) => (
                                <div
                                  key={item.amenityName}
                                  className="flex items-center space-x-3"
                                >
                                  <i
                                    className={`text-3xl las ${item.iconClassname}`}
                                  ></i>
                                  <span className=" ">{item.amenityName}</span>
                                </div>
                              ))
                          : null
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Meal Plans</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Choose your meal plan during your stay
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {roomTypeDetails !== null
              ? roomTypeDetails?.roomTypeDetails !== null
                ? roomTypeDetails?.roomTypeDetails?.roomMealPlanList !== null
                  ? roomTypeDetails.roomTypeDetails.roomMealPlanList.map(
                      (meal_plan_item, index) => (
                        <div
                          className={`p-4 ${
                            index % 2
                              ? ""
                              : "bg-neutral-100 dark:bg-neutral-800"
                          } ${
                            currentMealPlan === meal_plan_item.mealPlanId
                              ? "text-green-600"
                              : ""
                          } flex justify-between items-center space-x-4 rounded-lg cursor-pointer`}
                          onClick={() =>
                            setCurrentmealPlan(meal_plan_item.mealPlanId)
                          }
                        >
                          <span>{meal_plan_item.mealPlanName}</span>
                          {currentMealPlan === meal_plan_item.mealPlanId ? (
                            <span className="text-green-600 text-lg">
                              <i className="las la-check-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      )
                    )
                  : null
                : null
              : null}
          </div>
        </div>
      </div>
    );
  };

  // const renderSidebar = () => {
  //   return (
  //     <div className="listingSectionSidebar__wrap shadow-xl">
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold">
  //           {orgDetails.currency.currencyCode}{" "}
  //           {roomTypeRoomRateDetails?.roomRate !== undefined ? (
  //             <PriceComponent
  //               price={
  //                 (roomTypeRoomRateDetails?.roomRate * roomAmount) as number
  //               }
  //             />
  //           ) : (
  //             0
  //           )}
  //           <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
  //             /night
  //           </span>
  //         </span>
  //         {/* <StartRating /> */}
  //       </div>

  //       {/* FORM */}
  //       <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //         <StayDatesRangeInput className="flex-1 z-[11]" />
  //         <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <RoomsInput
  //           className="flex-1"
  //           roomAmount={roomAmount}
  //           setRoomAmount={setRoomAmount}
  //         />
  //         <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <GuestsInput
  //           guestAmount={guestAmount}
  //           setGuestAmount={setGuestAmount}
  //           className="flex-1"
  //         />
  //       </form>

  //       {/* SUM */}
  //       <div className="flex flex-col space-y-4">
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>
  //             {orgDetails.currency.currencyCode}{" "}
  //             {
  //               <PriceComponent
  //                 price={roomTypeRoomRateDetails?.roomRate as number}
  //               />
  //             }{" "}
  //             x {bookingDetails.nights} night
  //           </span>
  //           <span>
  //             {roomTypeRoomRateDetails !== null ? (
  //               <PriceComponent
  //                 price={
  //                   roomTypeRoomRateDetails?.roomRate *
  //                   bookingDetails?.nights *
  //                   roomAmount
  //                 }
  //               />
  //             ) : (
  //               0
  //             )}
  //           </span>
  //         </div>
  //         {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>Service Charge</span>
  //           <span>${billing?.serviceCharge}</span>
  //         </div> */}
  //         <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //         {/* <div className="flex justify-between font-semibold">
  //           <span>Total</span>
  //           <span>${bookingDetails?.total}</span>
  //         </div> */}
  //       </div>

  //       {/* SUBMIT */}
  //       <ButtonPrimary onClick={handleReserve}>
  //         {roomReserved ? "Continue" : "Reserve"}
  //       </ButtonPrimary>
  //     </div>
  //   );
  // };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      {roomTypeDetails !== null ? (
        roomTypeDetails?.roomTypeDetails !== null ? (
          roomTypeDetails?.roomTypeDetails?.roomTypeImageList !== null ? (
            <header className="rounded-md sm:rounded-xl">
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                  onClick={handleOpenModalImageGallery}
                >
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={
                      imageServerURL +
                      roomTypeDetails?.roomTypeDetails?.roomTypeImageList[0]
                        .imagePath
                    }
                    alt=""
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                </div>
                {roomTypeDetails?.roomTypeDetails?.roomTypeImageList
                  .filter((_, i) => i >= 1 && i < 5)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                        index >= 3 ? "hidden sm:block" : ""
                      }`}
                    >
                      <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                        <img
                          className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                          src={imageServerURL + item.imagePath}
                          alt=""
                          sizes="400px"
                        />
                      </div>

                      {/* OVERLAY */}
                      <div
                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                        onClick={handleOpenModalImageGallery}
                      />
                    </div>
                  ))}

                {/* <button
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                  onClick={handleOpenModalImageGallery}
                >
                  <Squares2X2Icon className="w-5 h-5" />
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </button> */}
              </div>
            </header>
          ) : null
        ) : null
      ) : null}

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        {/* <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10"> */}
        <div className="w-full space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection3()}
          {/* {renderSection4()} */}
          {/* <SectionDateRange disabledDates={disabledDateList} /> */}
        </div>

        {/* SIDEBAR */}
        {/* <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div> */}
      </main>
    </div>
  );
};

export default function ListingStayDetailModal({
  id,
}: {
  id: number | string;
}) {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer room_type_id={id} />
    </DetailPagetLayout>
  );
}