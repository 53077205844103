import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import CarsSearchForm from "./(car-search-form)/CarsSearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import {
  GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE,
  SAVE_ACTIVITY_LOG_RESPONSE,
  SEARCH_ROOMS_TO_MAKE_RESERVATION_RESPONSE,
} from "traverse/response_body";
import { getAvailableRoomTypeList, saveActivityLog, searchRoomsForReservation } from "traverse";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { BookedRoomGuestDetails } from "data/types";
import { FormatDate } from "utils/converSelectedDateToString";
import { addSearchResult } from "../../redux/slices/roomSearchResultSlice";
import {
  updateGuestSearchCriteria,
  updateRoomSearchCriteria,
  updateSearchStatus,
} from "../../redux/slices/searchCriteriaSlice";
import {
  removeAllRoomType,
  updateTotal,
} from "../../redux/slices/bookingSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Button from "shared/Button/Button";
import { updateTransId } from "../../redux/slices/activityLogSlice";

const HeroSearchForm2Mobile = () => {
  const [showModal, setShowModal] = useState(false);

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  // const bookingDetails = useSelector((state: RootState) => state.booking);
  const searchedCriteriaDetails = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  const orgDetails = useSelector((state: RootState) => state.org);
  const activityLogDetails = useSelector((state: RootState) => state.activityLogSlice);

  const dispatch = useDispatch();
  const [guestAmount, setGuestAmount] = useState<BookedRoomGuestDetails[]>([]);
  const [roomAmount, setRoomAmount] = useState<number>(1);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);

  useEffect(() => {
    setGuestAmount([
      {
        guestTypeId: 1,
        amount: 1,
      },
    ]);
  }, []);

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total ? __total : 1;
  };

  const handleSearch = () => {
    let guestTotalCount = calculateTotalGuests(
      guestAmount.map((guest_type) => guest_type.amount)
    );
    if (guestTotalCount >= roomAmount) {
      dispatch(updateSearchStatus(true));
      new Promise<GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE>((resolve) => {
        let _room_search_result_promise = getAvailableRoomTypeList({
          clientId: orgDetails.client.clientId,
          orgId: orgDetails.orgId,
          checkInDate: FormatDate(searchedCriteriaDetails.checkInDate),
          checkOutDate: FormatDate(searchedCriteriaDetails.checkOutDate),
          guestAmount: calculateTotalGuests(
            guestAmount.map((guest_type) => guest_type.amount)
          ),
          roomAmount: roomAmount,
          tourOperatorId: orgDetails.tourOperatorId,
          marketId: orgDetails.marketId,
        });
        resolve(_room_search_result_promise);
      }).then((response) => {
        if (!response.hasError) {
          // response
          dispatch(updateGuestSearchCriteria(guestAmount));
          dispatch(updateRoomSearchCriteria(roomAmount));
          dispatch(addSearchResult(response));
          dispatch(removeAllRoomType());
          dispatch(updateTotal(0));
        }
        dispatch(updateSearchStatus(false));
        let _activity_log_data = {
          checkInDate: FormatDate(searchedCriteriaDetails.checkInDate),
          checkOutDate: FormatDate(searchedCriteriaDetails.checkOutDate),
          guestAmount: calculateTotalGuests(
            guestAmount.map((guest_type) => guest_type.amount)
          ),
          roomAmount: roomAmount,
          tourOperatorId: orgDetails.tourOperatorId,
          marketId: orgDetails.marketId,
        };
        new Promise<SAVE_ACTIVITY_LOG_RESPONSE>((resolve) => {
          let _save_activity_log_promise = saveActivityLog({
            clientId: orgDetails.client.clientId,
            orgId: orgDetails.orgId,
            transId: activityLogDetails.transId,
            taskName: "Room Search",
            description: JSON.stringify(_activity_log_data),
            createdUsername: "Sapro IBE",
            reservationId: 0,
            reservationLineId: 0,
            primaryKey: 0,
          });
          resolve(_save_activity_log_promise);
        }).then((response) => {
          if (!response.hasError) {
            dispatch(updateTransId(response.logTransId));
          }
        });
      });
    } else {
      setShowAlertMessage(true);
    }
  };
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">When to?</span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">
              Any week • Add guests • Add Room
            </span>
          </div>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>

        <Transition appear show={showAlertMessage} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => setShowAlertMessage(false)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
              </Transition.Child>

              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block py-8">
                  <div className="inline-flex py-8 px-10 flex-col items-center w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                    <div className="w-16 h-16 border-2 border-orange-500 bg-orange-100 rounded-full flex justify-center items-center">
                      <i className="las la-exclamation text-3xl text-orange-500"></i>
                    </div>
                    <div className="font-medium text-2xl pb-2">Warning!</div>
                    <div className="text-lg pb-6">
                      Room Count must be less than Guest Count.
                    </div>
                    <Button
                      onClick={() => setShowAlertMessage(false)}
                      className={`bg-orange-200 p-0`}
                    >
                      Try Again
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                        {["Stay"].map((item, index) => (
                          <Tab key={index} as={Fragment}>
                            {({ selected }) => (
                              <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                <div
                                  className={`${
                                    selected ? "text-black dark:text-white" : ""
                                  }  `}
                                >
                                  {item}
                                </div>
                                {selected && (
                                  <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                )}
                              </div>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm
                                roomAmount={roomAmount}
                                setRoomAmount={setRoomAmount}
                                guestAmount={guestAmount}
                                setGuestAmount={setGuestAmount}
                              />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm
                                roomAmount={roomAmount}
                                setRoomAmount={setRoomAmount}
                                guestAmount={guestAmount}
                                setGuestAmount={setGuestAmount}
                              />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <CarsSearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <FlightSearchForm />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button>
                        <ButtonSubmit
                          onClick={() => {
                            handleSearch();
                            closeModal();
                          }}
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
