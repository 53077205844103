import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  activityLogData,
} from "data/types";

const initialState: activityLogData = {
  transId: "",
};

export const activityLogSlice = createSlice({
  name: "activity_log",
  initialState,
  reducers: {
    updateTransId: (state, action: PayloadAction<string>) => {
      state.transId = action.payload;
    },
    clearTransId: (state) => {
      state.transId = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTransId, clearTransId } = activityLogSlice.actions;

export default activityLogSlice.reducer;
