import { DateRage } from "components/HeroSearchForm/type";

const converSelectedDateToString = ([startDate, endDate]: DateRage) => {
  const dateString =
    (startDate?.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
    }) || "") +
    (endDate
      ? " - " +
        endDate?.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        })
      : "");
  return dateString;
};

export default converSelectedDateToString;

export const FormatDate: (date_to_format: Date) => string = (date_to_format) => {
  /**
   * Return current date in yyyy-mm-dd format
   * eg:- 1982-05-20
   *
   * @return {string} current date in yyyy-mm-dd format
   */
  let date = date_to_format;
  return `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString()
  }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()}`;
};

export const ConvertMillisToDate: (date_in_millis: number) => string = (date_in_millis) => {
  /**
   * Convert a millisecond value to date
   * eg:- 390700800000 => 1982-05-20
   *
   * @params {int} date_in_millis - date value in miliseconds
   * @return {string}               date value in yyyy-mm-dd format
   */
  if (!date_in_millis || date_in_millis < 0) {
    return "Not Specified";
  } else {
    let date = new Date(date_in_millis);
    return `${date.getFullYear()}-${
      date.getMonth() + 1 >= 10
        ? (date.getMonth() + 1).toString()
        : "0" + (date.getMonth() + 1).toString()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()}`;
  }
};
