import axios, { AxiosInstance } from "axios";

import {
  BACKEND_URL,
  GET_MORE_ROOM_TYPE_DETAILS,
  GET_ORG_DETAILS,
  GET_ROOM_TYPE_ROOM_RATE_DETAILS,
  SAVE_IBE_RESERVATION,
  GET_BOOKING_FORM_DP_DATA,
  EXECUTE_PG_PAY,
  SEARCH_ROOMS_TO_MAKE_RESERVATION,
  CONFIRM_IBE_RESERVATION,
  GET_RESERVATION_STATUS,
  GET_AVAILABLE_ROOM_TYPE_LIST,
  GET_IP_DETAILS,
  SAVE_ACTIVITY_LOG,
} from "./endpoints";
import {
  CONFIRM_RESERVATION_REQUEST,
  GET_BOOKING_FORM_DP_DATA_REQUEST,
  GET_MORE_ROOM_TYPE_DETAILS_REQUEST,
  GET_ORG_DETAILS_REQUEST,
  GET_ROOM_TYPE_ROOM_RATE_DETAILS_REQUEST,
  SAVE_ACTIVITY_LOG_REQUEST,
  SAVE_RESERVATION_REQUEST,
  SEARCH_ROOMS_TO_MAKE_RESERVATION_REQUEST,
} from "./request_body";
import {
  CONFIRM_RESERVATION_RESPONSE,
  GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE,
  GET_BOOKING_FORM_DP_DATA_RESPONSE,
  GET_MORE_ROOM_TYPE_DETAILS_RESPONSE,
  GET_ORG_DETAILS_RESPONSE,
  GET_RESERVATION_STATUS_RESPONSE,
  GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE,
  IP_DETAILS_RESPONSE,
  SAVE_ACTIVITY_LOG_RESPONSE,
  SAVE_RESERVATION_RESPONSE,
  SEARCH_ROOMS_TO_MAKE_RESERVATION_RESPONSE,
} from "./response_body";

const requestInstance: AxiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

const pgRrequestInstance: AxiosInstance = axios.create({
  baseURL: "http://localhost:5000",
});

export function getOrgDetailsEndpoint(
  data: GET_ORG_DETAILS_REQUEST
): Promise<GET_ORG_DETAILS_RESPONSE> {
  return requestInstance
    .post(GET_ORG_DETAILS, data)
    .then((response) => response.data);
}

export function getMoreRoomTypeDetailsEndpoint(
  data: GET_MORE_ROOM_TYPE_DETAILS_REQUEST
): Promise<GET_MORE_ROOM_TYPE_DETAILS_RESPONSE> {
  return requestInstance
    .post(GET_MORE_ROOM_TYPE_DETAILS, data)
    .then((response) => response.data);
}

export function getRoomTypeRoomRateDetailsEndpoint(
  data: GET_ROOM_TYPE_ROOM_RATE_DETAILS_REQUEST
): Promise<GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE> {
  return requestInstance
    .post(GET_ROOM_TYPE_ROOM_RATE_DETAILS, data)
    .then((response) => response.data);
}

export function saveIBEReservationEndpoint(
  data: SAVE_RESERVATION_REQUEST
): Promise<SAVE_RESERVATION_RESPONSE> {
  return requestInstance
    .post(SAVE_IBE_RESERVATION, data)
    .then((response) => response.data);
}

export function getBookReservationFormData(
  data: GET_BOOKING_FORM_DP_DATA_REQUEST
): Promise<GET_BOOKING_FORM_DP_DATA_RESPONSE> {
  return requestInstance
    .post(GET_BOOKING_FORM_DP_DATA, data)
    .then((response) => response.data);
}

export function executeBookingPaymentProcess(data: FormData) {
  // pgRrequestInstance.defaults.maxRedirects = 0; // Set to 0 to prevent automatic redirects
  // pgRrequestInstance.interceptors.response.use(
  //   (response) => {
  //     console.log(
  //       "[DEBUG] handleBookingSubmission.executeBookingPaymentProcess.redirect: ",
  //       response
  //     );
  //     return response;
  //   },
  //   (error) => {
  //     if (error.response && [301, 302].includes(error.response.status)) {
  //       const redirectUrl = error.response.headers.location;
  //       return pgRrequestInstance.get(redirectUrl);
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  return pgRrequestInstance
    .post("pay", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      console.log("[DEBUG] pgRrequestInstance.response.headers: ", res);
      console.log(
        "[DEBUG] pgRrequestInstance.response.headers.cookie: ",
        res.data["set-cookie"]
      );
      console.log(
        "[DEBUG] pgRrequestInstance.response.headers.cookie.jsessionid: ",
        res.data["set-cookie"][0]
      );

      let splittedCookieList = res.data["set-cookie"][0].split(";");
      let jsessionidcookie = splittedCookieList[0].split("=");

      console.log(
        "[DEBUG] pgRrequestInstance.splittedCookieList: ",
        splittedCookieList
      );
      console.log(
        "[DEBUG] pgRrequestInstance.jsessionidcookie: ",
        jsessionidcookie
      );

      axios.get(
        "https://testsecureacceptance.cybersource.com/billing"
        // ,{
        // headers: {
        //   Cookie: `JSESSIONID=${jsessionidcookie[0]};`
        // }
        // }
      );
    });
}

export function searchRoomsForReservation(
  data: SEARCH_ROOMS_TO_MAKE_RESERVATION_REQUEST
): Promise<SEARCH_ROOMS_TO_MAKE_RESERVATION_RESPONSE> {
  return requestInstance
    .post(SEARCH_ROOMS_TO_MAKE_RESERVATION, data)
    .then((response) => response.data);
}

export function confirmIBEReservationEndpoint(
  data: CONFIRM_RESERVATION_REQUEST
): Promise<CONFIRM_RESERVATION_RESPONSE> {
  return requestInstance
    .post(CONFIRM_IBE_RESERVATION, data)
    .then((response) => response.data);
}

export function getIbeReservationStatusEndpoint(
  data: CONFIRM_RESERVATION_REQUEST
): Promise<GET_RESERVATION_STATUS_RESPONSE> {
  return requestInstance
    .post(GET_RESERVATION_STATUS, data)
    .then((response) => response.data);
}

export function getAvailableRoomTypeList(
  data: SEARCH_ROOMS_TO_MAKE_RESERVATION_REQUEST
): Promise<GET_AVAILABLE_ROOM_TYPE_LIST_RESPONSE> {
  return requestInstance
    .post(GET_AVAILABLE_ROOM_TYPE_LIST, data)
    .then((response) => response.data);
}

export function saveActivityLog(
  data: SAVE_ACTIVITY_LOG_REQUEST
): Promise<SAVE_ACTIVITY_LOG_RESPONSE> {
  return requestInstance
    .post(SAVE_ACTIVITY_LOG, data)
    .then((response) => response.data);
}

export function getIpData(): Promise<IP_DETAILS_RESPONSE> {
  return axios.get(GET_IP_DETAILS)
    .then((response) => response.data);
}
