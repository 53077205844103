import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

export interface RoomsInputProps {
  className?: string;
  roomAmount: number;
  setRoomAmount?: any;
}

const RoomsInput: FC<RoomsInputProps> = ({
  className = "flex-1",
  roomAmount,
  setRoomAmount,
}) => {
  const [totalRooms, setTotalRooms] = useState<number>(1);

  useEffect(() => {
    setTotalRooms(roomAmount)
  }, [roomAmount])

  const handleChangeData = (value: number) => {
    setRoomAmount(value);
    setTotalRooms(value);
  };

  return (
    <div className="flex justify-between">
      <div className={`flex items-center focus:outline-none p-3 space-x-3`}>
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-door-open text-3xl"></i>
        </div>
        <div className="flex justify-between">
          <div className="flex-grow">
            <span className="block xl:text-lg font-semibold min-w-max">
              {totalRooms || ""} {totalRooms === 1 ? "Room" : "Rooms"}
            </span>
            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
              {totalRooms === 1 ? "Room" : "Rooms"}
            </span>
          </div>
        </div>
      </div>
      <NcInputNumber
        className="p-3"
        defaultValue={totalRooms}
        onChange={(value) => handleChangeData(value)}
        max={10}
        min={1}
      />
    </div>
  );
};

export default RoomsInput;
