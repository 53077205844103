import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface SectionDateRangeProps{
  disabledDates?: Date[];
}

const SectionDateRange:FC<SectionDateRangeProps> = ({disabledDates}) => {

  const bookingDetails = useSelector((state: RootState) => state.booking);

  const [startDate, setStartDate] = useState<Date | null>(
    bookingDetails.checkInDate
  );
  const [endDate, setEndDate] = useState<Date | null>(
    bookingDetails.checkOutDate
  );
  const onChangeDate = (dates: [Date | null, Date | null]) => {
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="">
          <DatePicker
            selected={startDate}
            onChange={onChangeDate}
            startDate={startDate}
            endDate={endDate}
            excludeDates={disabledDates}
            selectsRange
            disabled={true}
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        </div>
      </div>
    );
  };

  return renderSectionCheckIndate();
};

export default SectionDateRange;
