import { backendURL } from "../Config/Config";

export const BACKEND_URL: string = backendURL;

export const GET_ORG_DETAILS: string = "getIBEOrgDetails"; 
export const GET_MORE_ROOM_TYPE_DETAILS: string = "getIBERoomTypeDetails"; 
export const GET_ROOM_TYPE_ROOM_RATE_DETAILS: string = "getRoomTypeRoomRate"; 
export const GET_BOOKING_FORM_DP_DATA: string = "viewIBEBookReservation"; 
export const GET_RESERVATION_STATUS: string = "getIbeReservationStatus"; 
export const SEARCH_ROOMS_TO_MAKE_RESERVATION: string = "searchRoomForBooking"; 
export const GET_AVAILABLE_ROOM_TYPE_LIST: string = "getAvailableRoomTypeList"; 
export const GET_IP_DETAILS: string = "https://ipinfo.io/json"; 

export const EXECUTE_PG_PAY: string = "pay";

export const SAVE_IBE_RESERVATION: string = "saveIBEReservation"; 
export const SAVE_ACTIVITY_LOG: string = "saveIbeActivityLog"; 
export const CONFIRM_IBE_RESERVATION: string = "confirmIBEReservation"; 