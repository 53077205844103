import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { BookedRoom } from "data/types";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotal,
  removeRoomType,
} from "../../redux/slices/bookingSlice";
import { RootState } from "redux/store";
import { PriceComponent } from "utils/PriceComponent";
import Button from "shared/Button/Button";
import { useNavigate } from "react-router-dom";
import { BookingSummaryCardProps } from "./types";
import { SAVE_ACTIVITY_LOG_RESPONSE } from "traverse/response_body";
import { saveActivityLog } from "traverse";

const BookingSummaryCard: FC<BookingSummaryCardProps> = ({
  showProceedButton,
}) => {
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const searchedCriteriaDetails = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  const orgDetails = useSelector((state: RootState) => state.org);
  const activityLogDetails = useSelector(
    (state: RootState) => state.activityLogSlice
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeRoomTypeFromBill = (room_type_item: BookedRoom) => {
    dispatch(removeRoomType(room_type_item));
    dispatch(calculateTotal());
  };

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total;
  };

  const handleProceedToBook = () => {
    new Promise<SAVE_ACTIVITY_LOG_RESPONSE>((resolve) => {
      let _save_activity_log_promise = saveActivityLog({
        clientId: orgDetails.client.clientId,
        orgId: orgDetails.orgId,
        transId: activityLogDetails.transId,
        taskName: "Rooms Selected",
        description: JSON.stringify(bookingDetails.roomTypeList),
        createdUsername: "Sapro IBE",
        reservationId: 0,
        reservationLineId: 0,
        primaryKey: 0,
      });
      resolve(_save_activity_log_promise);
    }).then((response) => {
      navigate("/book-now");
    });
  };

  return (
    <div className="w-full flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 p-6 xl:p-8">
      <div className="font-medium text-lg border-b-2 border-neutral-500">
        Your Booking Summary
      </div>
      <div>
        <div className="flex-1 flex space-x-4">
          <svg
            className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
              stroke="#D1D5DB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className="flex flex-col">
            <span className="text-sm text-neutral-400">
              Check-In Date - Check-Out Date
            </span>
            <span className="mt-1.5 text-lg font-semibold">
              {new Date(
                bookingDetails.roomTypeList.length > 0
                  ? bookingDetails?.checkInDate
                  : searchedCriteriaDetails.checkInDate
              )?.toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
              {" - "}
              {new Date(
                bookingDetails.roomTypeList.length > 0
                  ? bookingDetails?.checkOutDate
                  : searchedCriteriaDetails.checkOutDate
              )?.toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <div className="text-orange-500 flex bg-orange-50 py-1 px-5 rounded-full">
            <i className="text-xl las la-cloud-moon"></i>
            <div className="ml-2 text-sm">{`${
              bookingDetails.roomTypeList.length > 0
                ? bookingDetails.nights
                : searchedCriteriaDetails.nights
            } night${
              bookingDetails.roomTypeList.length > 0
                ? bookingDetails.nights > 1
                  ? "s"
                  : ""
                : searchedCriteriaDetails.nights > 1
                ? "s"
                : ""
            }`}</div>
          </div>
        </div>
      </div>
      {bookingDetails.roomTypeList.length === 0 ? (
        <div className="border-2 rounded-lg border-dashed border-indigo-600 text-indigo-600 p-6 flex flex-col items-center">
          {/* <i className="las la-hand-point-left text-2xl"></i> */}
          <div className="font-medium">Choose a Room Type</div>
        </div>
      ) : (
        bookingDetails.roomTypeList.map((room_type_item) => (
          <div className="w-full flex flex-col sm:flex-row sm:items-center">
            <div className="w-full space-y-3">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-medium block mb-3">
                    {room_type_item.roomName}
                  </span>
                  <div className="flex">
                    <div className="flex items-center">
                      <div className="text-neutral-300 dark:text-neutral-400">
                        <i className="las la-door-open text-2xl"></i>
                      </div>
                      <div className="text-sm ml-2">
                        {room_type_item.roomAmount}{" "}
                        {`Room${room_type_item.roomAmount > 1 ? "s" : ""}`}
                      </div>
                    </div>
                    <div className="flex items-center ml-5">
                      <div className="text-neutral-300 dark:text-neutral-400">
                        <UserPlusIcon className="w-6 h-6" />
                      </div>
                      <div className="text-sm ml-2">
                        {calculateTotalGuests(
                          room_type_item.guests.map(
                            (guest_type) => guest_type.amount
                          )
                        )}
                        {` Guest${
                          calculateTotalGuests(
                            room_type_item.guests.map(
                              (guest_type) => guest_type.amount
                            )
                          ) > 1
                            ? "s"
                            : ""
                        }`}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-neutral-300 dark:text-neutral-400">
                      <i className="las la-hamburger text-2xl"></i>
                    </div>
                    <div className="text-sm ml-2">
                      {room_type_item.mealPlanName}
                    </div>
                  </div>
                </div>
                <div className="border p-2 rounded-lg border-2 border-red-300 cursor-pointer">
                  <TrashIcon
                    className="w-4 text-sm text-red-600"
                    onClick={() => removeRoomTypeFromBill(room_type_item)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {bookingDetails.total ? (
        <div className="flex text-2xl font-medium justify-between">
          <div>Pay Now</div>
          <div className="flex">
            <div>{orgDetails.currency.currencyCode}</div>
            <div className="ml-2 pr-4 border-r-4 border-green-500">
              <PriceComponent price={bookingDetails.total as number} />
            </div>
          </div>
        </div>
      ) : null}
      {showProceedButton ? (
        <Button
          onClick={() => handleProceedToBook()}
          className={`text-green-500 border-2 p-0 border-green-500 ${
            bookingDetails.total ? "" : "pointer-events-none opacity-50"
          }`}
        >
          Proceed To Book
        </Button>
      ) : null}
    </div>
  );
};

export default BookingSummaryCard;
