import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { GuestsObject } from "./type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface RoomInputProps {
  fieldClassName?: string;
  className?: string;
  hasButtonSubmit?: boolean;
  roomAmount: number;
  setRoomAmount?: any;
  handleClick: () => void;
}

const RoomInput: FC<RoomInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  hasButtonSubmit = true,
  roomAmount,
  setRoomAmount,
  handleClick
}) => {
  const [totalRooms, setTotalRooms] = useState<number>(1);

  useEffect(() => {
    setTotalRooms(roomAmount);
  }, [roomAmount]);

  const handleChangeData = (value: number) => {
    setRoomAmount(value);
    setTotalRooms(value);
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <i className=" las la-door-open text-3xl"></i>
              </div>
              <div className="flex justify-between">
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold min-w-max">
                    {totalRooms || ""} {totalRooms === 1 ? "Room" : "Rooms"}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    {totalRooms === 1 ? "Room" : "Rooms"}
                  </span>
                </div>
              </div>

            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit handleClick={handleClick} />
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="p-3"
                defaultValue={totalRooms}
                onChange={(value) => handleChangeData(value)}
                max={10}
                min={1}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default RoomInput;
