import { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { SearchRoomType, StayDataType } from "data/types";
import PropertyCardH from "../../components/PropertyCardH/PropertyCardH";
import {
  AVAILABLE_ROOM_TYPE,
  SEARCH_ROOMS_TO_MAKE_RESERVATION_RESPONSE,
} from "traverse/response_body";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { SearchRealEstateTab } from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm";
import { transpileModule } from "typescript";
import { BallTriangle, Grid } from "react-loader-spinner";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridFeaturePropertyProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  searchResult?: any;
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Sapro recommends for you",
  headingIsCenter,
  tabs = ["Suggestions"],
  searchResult = null,
}) => {
  const searchedCriteriaDetails = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  const searchRoomResult = useSelector(
    (state: RootState) => state.roomSearchResult
  );
  const [roomTypeList, setRoomTypeList] = useState<AVAILABLE_ROOM_TYPE[]>([]);

  useEffect(() => {
    let searchResultRoomTypeList =
      searchRoomResult?.availableRoomTypeList?.filter(
        (search_room_combination, index) =>
          search_room_combination.mealPlanRateList.findIndex(
            (meal_plan) => meal_plan.rate > 0
          ) !== -1 &&
          search_room_combination.availableRooms >=
            searchedCriteriaDetails.roomsAmount
      );
    setRoomTypeList(searchResultRoomTypeList);
    
  }, [searchRoomResult]);

  const renderCard = (stay: AVAILABLE_ROOM_TYPE, index: number) => {   
    return (
      <PropertyCardH key={index} className="relative full mb-3" data={stay} />
    );
  };

  const identicalRoomType = (room_type_list: SearchRoomType) => {
    let room_id = room_type_list.roomTypeList[0].roomTypeId;
    let room_id_count = 0;
    room_type_list.roomTypeList.forEach((room_type) => {
      if (room_type.roomTypeId === room_id) {
        room_id_count++;
      }
    });
    if (room_id_count === room_type_list.roomTypeList.length) {
      return true;
    }
    return false;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      {/* <HeaderFilter
        tabActive={"Suggestions"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      /> */}
      {/* <div className={`md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 `}>
        {searchRoomResult?.searchResultList?.map(
          (search_room_combination, index) =>
          search_room_combination.mealPlanRateList.findIndex(meal_plan => meal_plan.rate > 0) !== -1
              ? renderCard(search_room_combination, index)
              : null
        )}
      </div> */}
      {/* <div className={`md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 `}>
        {searchRoomResult?.availableRoomTypeList?.map(
          (search_room_combination, index) =>
            search_room_combination.mealPlanRateList.findIndex(
              (meal_plan) => meal_plan.rate > 0
            ) !== -1 && identicalRoomType(search_room_combination)
              ? renderCard(search_room_combination, index)
              : null
        )}
      </div> */}
      <div className={`md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 `}>
        {/* {searchRoomResult?.availableRoomTypeList?.map(
          (search_room_combination, index) =>
            search_room_combination.mealPlanRateList.findIndex(
              (meal_plan) => meal_plan.rate > 0
            ) !== -1 &&
            search_room_combination.availableRooms >=
              searchedCriteriaDetails.roomsAmount
              ? renderCard(search_room_combination, index)
              : null
        )} */}
        {searchedCriteriaDetails.searching ? (
          <div className="md:flex justify-center">
            <div className="flex flex-col items-center">
              <Grid
                visible={searchedCriteriaDetails.searching}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
              />
              <div className="mt-5 font-medium text-base">Searching...</div>
            </div>
          </div>
        ) : roomTypeList.length > 0 ? (
          roomTypeList.map((search_room_combination, index) =>
            renderCard(search_room_combination, index)
          )
        ) : (
          <div className="border-2 border-orange-500 border-dashed rounded-2xl flex flex-col justify-center items-center text-orange-500 py-8">
            <div className="w-10 h-10 border-2 border-orange-500 rounded-full flex justify-center items-center">
              <i className="las la-exclamation text-xl"></i>
            </div>
            <div className="text-lg pt-2 text-center">
              No Rooms Available For Selected Date Range
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
