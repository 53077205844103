import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { addDateRange } from "../../../redux/slices/searchCriteriaSlice";
import { useLocation } from "react-router-dom";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  disabledDates?: Date[];
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
  disabledDates = [],
}) => {
  const searchedCriteriaDetails = useSelector((state: RootState) => state.searchedCriteria);
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.href);
  const urlExtractedNationality = queryParams.get("na");
  let urlExtractedCheckInDate = queryParams.get("check-in");
  let urlExtractedCheckOutDate = queryParams.get("check-out");
  // Replace special characters
  if (urlExtractedCheckInDate !== null) {
    if (!urlExtractedCheckInDate.includes("-")) {
      let dateString = urlExtractedCheckInDate
        .replace("%2F", "/")
        .replace("%20", " ");

      // Current year
      var currentYear = new Date().getFullYear();

      // Construct date string in MM/DD/YYYY format
      var validDateString = dateString + "/" + currentYear;

      // Create a new Date object
      urlExtractedCheckInDate = validDateString;
    }
  }
  // Replace special characters
  if (urlExtractedCheckOutDate !== null) {
    if (!urlExtractedCheckOutDate.includes("-")) {
      let dateString = urlExtractedCheckOutDate
        .replace("%2F", "/")
        .replace("%20", " ");

      // Current year
      var currentYear = new Date().getFullYear();

      // Construct date string in MM/DD/YYYY format
      var validDateString = dateString + "/" + currentYear;

      // Create a new Date object
      urlExtractedCheckOutDate = validDateString;
    }
  }

  const [startDate, setStartDate] = useState<Date | null>(
    urlExtractedCheckInDate === null
      ? new Date(searchedCriteriaDetails.checkInDate)
      : new Date(urlExtractedCheckInDate)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    urlExtractedCheckOutDate === null
      ? new Date(searchedCriteriaDetails.checkOutDate)
      : new Date(urlExtractedCheckOutDate)
  );

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      dispatch(addDateRange([startDate, endDate]));
    }
  }, [startDate, endDate]);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    const isRangeDisabled =
      start &&
      end &&
      disabledDates.some((date) => date >= start && date <= end);
    if (!isRangeDisabled) {
      setStartDate(start);
      setEndDate(end);
      if (start !== null && end !== null) {
        dispatch(addDateRange([start, end]));
      }
    }
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-5/6 z-10 mt-3 top-full w-screen max-w-sm -translate-x-5/6 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  excludeDates={disabledDates
                    .filter(Boolean)
                    .map((item) => new Date(item))}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  // filterDate={(date) => {
                  //   return !disabledDates.some((disabledDate) =>
                  //     isSameDay(date, disabledDate)
                  //   );
                  // }}
                  filterDate={(date) => {
                    const isDisabled = !disabledDates.some((disabledDate) =>
                      isSameDay(date, disabledDate)
                    );
                    return isDisabled;
                  }}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}
