import React, { FC, Fragment, useState } from "react";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject, GuestsObjects } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import RoomInput from "components/HeroSearchForm/RoomInput";
import RoomsInput from "../RoomsInput";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BookedRoomGuestDetails } from "data/types";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface StaySearchFormProps {
  roomAmount: number;
  setRoomAmount: any;
  guestAmount: BookedRoomGuestDetails[];
  setGuestAmount: any;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  roomAmount,
  setRoomAmount,
  guestAmount,
  setGuestAmount,
}) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "dates" | "guests" | "Rooms"
  >("dates");
  // const bookingDetails = useSelector((state: RootState) => state.booking);
  const searchedCriteriaDetails = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  //
  // const [locationInputTo, setLocationInputTo] = useState("");
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });
  const [guestRoomInput, setGuestRoomInput] = useState<GuestsObjects>({
    guestRooms: 1,
  });
  // const [guestAmount, setGuestAmount] = useState<BookedRoomGuestDetails[]>([]);
  // const [roomAmount, setRoomAmount] = useState<number>(1);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlExtracteCheckInDate = queryParams.get("check-in");
  const urlExtracteCheckOutDate = queryParams.get("check-out");
  const [startDate, setStartDate] = useState<Date | null>(
    urlExtracteCheckInDate === null
      ? new Date(searchedCriteriaDetails.checkInDate)
      : new Date(urlExtracteCheckInDate)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    urlExtracteCheckOutDate === null
      ? new Date(searchedCriteriaDetails.checkOutDate)
      : new Date(urlExtracteCheckOutDate)
  );

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total ? __total : 1;
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} guests`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Who</span>
            <span>
              {calculateTotalGuests(
                guestAmount.map((guest_type) => guest_type.amount)
              ) || `Add guests`}
            </span>
          </button>
        ) : (
          <GuestsInput
            defaultValue={guestInput}
            guestAmount={guestAmount}
            setGuestAmount={setGuestAmount}
            onChange={setGuestInput}
          />
        )}
      </div>
    );
  };

  const renderInputRooms = () => {
    const isActive = fieldNameShow === "Rooms";
    let guestRoomSelected = "";
    if (guestRoomInput.guestRooms) {
      const guestsRoom = guestRoomInput.guestRooms || 0;
      guestRoomSelected += `${guestsRoom} rooms`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("Rooms")}
          >
            <span className="text-neutral-400">How Many</span>
            <span>{roomAmount || `Add Rooms`}</span>
          </button>
        ) : (
          <RoomsInput
            defaultValue={guestRoomInput}
            onChange={setGuestRoomInput}
            roomAmount={roomAmount}
            setRoomAmount={setRoomAmount}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
        {/*  */}
        {renderInputRooms()}
      </div>
    </div>
  );
};

export default StaySearchForm;
