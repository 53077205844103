import React, { FC, Fragment, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Badge from "shared/Badge/Badge";
import { BookedRoom } from "data/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { imageServerURL } from "Config/Config";
import {
  addDateRange,
  addRoomType,
  updateTotal,
} from "../../redux/slices/bookingSlice";
import {
  AVAILABLE_ROOM_TYPE,
  GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE,
} from "traverse/response_body";
import { getRoomTypeRoomRateDetailsEndpoint } from "traverse";
import { FormatDate } from "utils/converSelectedDateToString";
import { PriceComponent } from "utils/PriceComponent";
import {
  MinusCircleIcon,
  PlusCircleIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ListingStayDetailModal from "components/ListingDetailModal/listing-stay-detail/ListingStayDetailModal";
import Button from "shared/Button/Button";

export interface PropertyCardHProps {
  className?: string;
  data?: AVAILABLE_ROOM_TYPE;
}
const PropertyCardH: FC<PropertyCardHProps> = ({ className = "", data }) => {
  const orgDetails = useSelector((state: RootState) => state.org);
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const searchedCriteriaDetails = useSelector(
    (state: RootState) => state.searchedCriteria
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentMealPlan, setCurrentMealPlan] = useState<{
    mealPlanId: number;
    mealPlanName: string;
    rate: number;
  } | null>(null);
  const [roomCount, setRoomCount] = useState<number>(
    searchedCriteriaDetails.roomsAmount
  );
  let [isOpenModalStayRoomType, setIsOpenModalStayRoomType] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [cartUpdated, setCartUpdated] = useState(true);

  useEffect(() => {
    let detectedRoomType = bookingDetails.roomTypeList.find(
      (_room_type) => _room_type.roomId === data?.roomTypeId
    );
    if (detectedRoomType !== undefined) {
      setAddedToCart(true);
    }
  }, []);

  useEffect(() => {
    if (data?.mealPlanRateList !== null) {
      let _zeroLessMealPlanList = data?.mealPlanRateList.filter(
        (meal_plan) => meal_plan.rate > 0
      );
      if (_zeroLessMealPlanList !== undefined) {
        let _meal_plan = _zeroLessMealPlanList[0];
        data?.mealPlanRateList.forEach((meal_plan) => {
          if (_meal_plan != undefined) {
            if (meal_plan.rate < _meal_plan?.rate && meal_plan.rate > 0) {
              _meal_plan = meal_plan;
            }
          }
        });
        if (_meal_plan !== undefined) {
          setCurrentMealPlan(_meal_plan);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    let roomFound = bookingDetails.roomTypeList.find(
      (_room_type) => _room_type.roomId === data?.roomTypeId
    );
    if (roomFound === undefined) {
      setAddedToCart(false);
      setCartUpdated(false);
    }
  }, [bookingDetails]);

  let __all_room_type_imageLists = data?.roomTypeImageList;

  let __all_room_type_images: string[] = [];
  if (__all_room_type_imageLists !== null) {
    __all_room_type_imageLists?.forEach((room_type_image_list) => {
      __all_room_type_images.push(
        imageServerURL + room_type_image_list.imagePath
      );
    });
  }

  function openModelStayRoomType() {
    setIsOpenModalStayRoomType(true);
  }

  function closeModalStayRoomType() {
    setIsOpenModalStayRoomType(false);
  }

  const renderSliderGallery = () => {
    return (
      <div
        className="flex-shrink-0 p-3 w-full sm:w-64 "
        onDoubleClick={() => setIsOpenModalStayRoomType(true)}
      >
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={__all_room_type_images}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_`}
        />
      </div>
    );
  };

  const renderRoomTieIch = (
    room_type_name: string,
    room_count: number,
    guest_count: number
  ) => {
    return (
      <div>
        <div className="inline-flex grid-cols-6 gap-2 mb-2">
          <div className="flex items-center space-x-2">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="las la-home"></i>
                </div>
              }
              color="gray"
            />
            <span className="text-xl min-w-max font-medium text-neutral-500 dark:text-neutral-400">
              {room_type_name}
            </span>
          </div>
        </div>
        {/* room specification */}
        <div className="flex items-center space-x-2">
          <Badge
            name={
              <div className="flex items-center">
                <i className="las la-door-open"></i>
              </div>
            }
            color="gray"
          />
          <span className="text-md text-neutral-500 dark:text-neutral-400">
            {room_count} {" Room"}
          </span>
        </div>
        {/* room specification */}
        <div className="flex items-center space-x-2">
          <Badge
            name={
              <div className="flex items-center">
                <i className="las la-user"></i>
              </div>
            }
            color="gray"
          />
          <span className="text-md text-neutral-500 dark:text-neutral-400">
            {guest_count}
            {" Guests"}
          </span>
        </div>
      </div>
    );
  };

  const handleBooking = () => {
    if (!addedToCart) {
      setAddedToCart(true);
      setCartUpdated(true);
    } else {
      setCartUpdated(true);
    }
    let _room_type_list: BookedRoom[] = [];

    new Promise<GET_ROOM_TYPE_ROOM_RATE_DETAILS_RESPONSE>((resolve) => {
      let _get_room_type_details_promise = getRoomTypeRoomRateDetailsEndpoint({
        clientId: orgDetails.client.clientId,
        orgId: orgDetails.orgId,
        roomTypeId: data?.roomTypeId as number,
        checkInDate: FormatDate(searchedCriteriaDetails.checkInDate),
        guestAmount: data?.maxOccupancy as number,
        mealPlanId: currentMealPlan?.mealPlanId as number,
        tourOperatorId: orgDetails.tourOperatorId,
        marketId: orgDetails.marketId,
      });
      resolve(_get_room_type_details_promise);
    }).then((response) => {
      dispatch(
        addDateRange([
          searchedCriteriaDetails.checkInDate,
          searchedCriteriaDetails.checkOutDate,
        ])
      );
      dispatch(
        addRoomType({
          roomId: data?.roomTypeId as number,
          mealPlanId: currentMealPlan?.mealPlanId as number,
          mealPlanName: currentMealPlan?.mealPlanName as string,
          roomName: data?.ibeRoomTypeName as string,
          guests: searchedCriteriaDetails.searchedGuestCriteria,
          roomAmount: roomCount,
          roomRate: response.roomRate,
        })
      );
      if (currentMealPlan?.rate !== undefined) {
        let currentTotal = 0;
        bookingDetails.roomTypeList.forEach((_room_type) => {
          if (_room_type.roomId !== data?.roomTypeId) {
            let roomTypeBill =
              _room_type.roomRate *
              searchedCriteriaDetails.nights *
              _room_type.roomAmount;
            currentTotal += roomTypeBill;
          }
        });
        let newlyAdded =
          currentMealPlan?.rate * searchedCriteriaDetails.nights * roomCount;
        currentTotal += newlyAdded;
        dispatch(updateTotal(currentTotal));
      }
      // navigate("/book-now");
    });
  };

  const handleMealPlanChange = (meal_item: typeof currentMealPlan) => {
    setCurrentMealPlan(meal_item);
    if (addedToCart) {
      setCartUpdated(false);
    }
  };

  const handleRoomCountChange = (count: number) => {
    setRoomCount(count);
    if (addedToCart) {
      setCartUpdated(false);
    }
  };

  const calculateTotalGuests = (guest_list: number[]) => {
    let __total = 0;
    guest_list.forEach((guest_type_amount) => (__total += guest_type_amount));
    return __total;
  };

  const renderOpenModelStayRoomType = () => {
    return (
      <Transition appear show={isOpenModalStayRoomType} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalStayRoomType}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="flex justify-end px-6 py-4 text-center">
                    {/* <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3> */}
                    <span className="left-3 top-3">
                      <ButtonClose onClick={closeModalStayRoomType} />
                    </span>
                  </div>
                  <ListingStayDetailModal id={data?.roomTypeId as number} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="md:flex w-full justify-between">
            <div>
              <div className="flex items-center space-x-2 mb-1">
                <h2
                  className="text-xl font-medium capitalize cursor-pointer"
                  onClick={() => setIsOpenModalStayRoomType(true)}
                >
                  {data?.ibeRoomTypeName}
                </h2>
                <i
                  className="text-neutral-400 las la-external-link-square-alt cursor-pointer"
                  onClick={() => setIsOpenModalStayRoomType(true)}
                ></i>
              </div>
              <Badge
                name={
                  <div className="flex items-center">
                    <i className="text-base las la-chart-pie"></i>
                    <span className="ml-1">{`${data?.availableRooms} Room${
                      data?.availableRooms ? "s" : ""
                    } Available`}</span>
                  </div>
                }
                color="indigo"
              />
              <div
                className={`flex items-center focus:outline-none p-3 space-x-3`}
              >
                <div className="text-neutral-400 dark:text-neutral-400">
                  <i className="las la-door-open text-2xl"></i>
                </div>
                <div className="flex justify-between">
                  <div className="flex">
                    <div>
                      <span className="block xl:text-base font-semibold min-w-max">
                        {roomCount || ""} {roomCount === 1 ? "Room" : "Rooms"}
                      </span>
                      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                        {roomCount === 1 ? "Room" : "Rooms"}
                      </span>
                    </div>
                    <MinusCircleIcon
                      className={`${
                        roomCount === 1 ? "pointer-events-none opacity-50" : ""
                      } ml-10 w-7 cursor-pointer text-neutral-400`}
                      onClick={() => handleRoomCountChange(roomCount - 1)}
                    />
                    <PlusCircleIcon
                      className={`${
                        roomCount === data?.availableRooms
                          ? "pointer-events-none opacity-50"
                          : ""
                      } ml-5 w-7 cursor-pointer text-neutral-400 disabled`}
                      onClick={() => handleRoomCountChange(roomCount + 1)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`flex-1 flex text-left items-center p-3 pt-0 space-x-3 focus:outline-none`}
              >
                <div className="text-neutral-400 dark:text-neutral-400">
                  <UserPlusIcon className="w-6 h-6" />
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-base font-semibold">
                    {searchedCriteriaDetails.searchedGuestCriteria.map(
                      (guest_type) => guest_type.amount
                    )}{" "}
                    Guests
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    {searchedCriteriaDetails.searchedGuestCriteria.map(
                      (guest_type) => guest_type.amount
                    )
                      ? "Guests"
                      : "Add guests"}
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <div className="flex mb-1">
                  {data?.amenityList.map((amenity_item, _) =>
                    _ < 5 ? (
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button>
                              <div className="cursor-pointer flex items-center justify-center rounded-full space-x-3 mr-5 w-8 h-8 bg-green-50">
                                <i
                                  className={`text-lg ${amenity_item.iconClassname} text-green-500`}
                                ></i>
                              </div>
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute left-0 z-10 sm:min-w-[200px] max-w-sm bg-green-100 rounded-lg px-5 py-2 my-3">
                                <div className="text-sm text-green-600">
                                  {amenity_item.amenityName}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ) : null
                  )}
                  {/* {data?.amenityList !== undefined &&
                  data?.amenityList.length > 0 ? (
                    <div className="flex items-center space-x-3 mr-5">
                      <i
                        className={`text-lg ${data?.amenityList[0].iconClassname} text-green-500`}
                      ></i>
                      <span className="text-sm">
                        {data?.amenityList[0].amenityName}
                      </span>
                    </div>
                  ) : null}
                  {data?.amenityList !== undefined &&
                  data?.amenityList.length > 1 ? (
                    <div className="flex items-center space-x-3">
                      <i
                        className={`text-lg ${data?.amenityList[1].iconClassname} text-green-500`}
                      ></i>
                      <p className="text-sm">
                        {data?.amenityList[1].amenityName}
                      </p>
                    </div>
                  ) : null} */}
                </div>
                {/* {data?.amenityList !== undefined &&
                data?.amenityList.length > 2 ? (
                  <div className="flex items-center space-x-3">
                    <i
                      className={`text-lg ${data?.amenityList[2].iconClassname} text-green-500`}
                    ></i>
                    <span className="text-sm">
                      {data?.amenityList[2].amenityName}
                    </span>
                  </div>
                ) : null} */}
              </div>
            </div>
            <div className="md:mt-0 mt-5 flex flex-col justify-between">
              <div>
                <div className="pr-10 min-w-max leading-none text-2xl font-medium border-r-4 border-orange-300">
                  <span className="mr-1">
                    {orgDetails.currency.currencyCode}
                  </span>
                  {
                    <PriceComponent
                      price={(currentMealPlan?.rate as number) * roomCount}
                    />
                  }
                </div>
                <Popover
                  className={`flex relative items-center focus:outline-none space-x-3 mt-3 mb-5`}
                >
                  {({ open }) => (
                    <>
                      <i className="text-neutral-600 las la-hamburger text-2xl"></i>
                      <Popover.Button className="relative w-full flex justify-between items-center">
                        <div className="flex flex-col items-start">
                          <span className="block xl:text-base font-semibold min-w-max">
                            {currentMealPlan?.mealPlanName}
                          </span>
                          <span className="block mt-1 text-sm text-neutral-600 leading-none font-light">
                            Meal Plan
                          </span>
                        </div>
                        <i
                          className={`las ${
                            open ? "rotate-180" : ""
                          } la-chevron-circle-down text-2xl`}
                        ></i>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
                          {data?.mealPlanRateList?.map((meal_item) =>
                            meal_item.rate > 0 ? (
                              <div
                                className={`flex justify-between items-center cursor-pointer py-4 px-3 rounded-md hover:bg-neutral-100 ${
                                  meal_item.mealPlanId ===
                                  currentMealPlan?.mealPlanId
                                    ? "font-medium border-2 border-indigo-600 text-indigo-600 bg-indigo-50"
                                    : ""
                                }`}
                                onClick={() => handleMealPlanChange(meal_item)}
                              >
                                <div>{meal_item.mealPlanName}</div>
                                <i
                                  className={`${
                                    meal_item.mealPlanId ===
                                    currentMealPlan?.mealPlanId
                                      ? "text-indigo-600"
                                      : "text-neutral-300"
                                  } las la-hamburger text-2xl`}
                                ></i>
                              </div>
                            ) : null
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
              {addedToCart ? (
                <Button
                  onClick={handleBooking}
                  className={`ttnc-ButtonPrimary ${
                    cartUpdated ? "pointer-events-none opacity-50" : ""
                  } disabled:bg-opacity-70 bg-emerald-600 hover:bg-emerald-700 text-neutral-50 ${className}`}
                >
                  Update Cart
                </Button>
              ) : (
                <ButtonPrimary
                  onClick={handleBooking}
                  className="min-w-max text-sm"
                >
                  Add to Cart
                </ButtonPrimary>
              )}
              {/* {bookingDetails.roomTypeList.find(
                (_room_type) => _room_type.roomId === data?.roomTypeId
              ) ? (
                <Button
                  onClick={handleBooking}
                  className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-emerald-600 hover:bg-primary-700 text-neutral-50 ${className}`}
                >
                  Update Cart
                </Button>
              ) : (
                <ButtonPrimary
                  onClick={handleBooking}
                  className="min-w-max text-sm"
                >
                  Add to Cart
                </ButtonPrimary>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-indigo-50 dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col sm:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {renderOpenModelStayRoomType()}
    </div>
  );
};

export default PropertyCardH;
