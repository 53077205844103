import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BookedRoom,
  BookedRoomGuestDetails,
  RoomReservationBill,
} from "data/types";
import { getDateDifferenceInDays } from "utils/dateUtils";

let currentDate = new Date();
let tommorrowDate = new Date();
tommorrowDate.setDate(currentDate.getDate() + 1);

const initialState: RoomReservationBill = {
  nights: 1,
  checkInDate: currentDate,
  checkOutDate: tommorrowDate,
  roomsAmount: 1,
  serviceCharge: 0,
  total: 0,
  roomTypeList: [],
  reservationCode: "",
  searchedGuestCriteria: [],
};

export const bookingSlice = createSlice({
  name: "room__booking",
  initialState,
  reducers: {
    updateReservationCode: (state, action: PayloadAction<string>) => {
      state.reservationCode = action.payload;
    },
    addBookingDetails: (state, action: PayloadAction<RoomReservationBill>) => {
      state = action.payload;
    },
    addDateRange: (state, action: PayloadAction<[Date, Date]>) => {
      let startDate = action.payload[0];
      let endDate = action.payload[1];
      state.checkInDate = startDate;
      state.checkOutDate = endDate;
      state.nights = getDateDifferenceInDays(startDate, endDate);
    },
    addRoomType: (state, action: PayloadAction<BookedRoom>) => {
      let roomTypeIndex = state.roomTypeList.findIndex(
        (room_type_item) => room_type_item.roomId === action.payload.roomId
      );
      if (roomTypeIndex === -1) {
        state.roomTypeList = [...state.roomTypeList, action.payload];
      } else {
        state.roomTypeList[roomTypeIndex] = action.payload;
      }
    },
    addRoomTypeList: (state, action: PayloadAction<BookedRoom[]>) => {
      state.roomTypeList = action.payload;
    },
    removeRoomType: (state, action: PayloadAction<BookedRoom>) => {
      let roomTypeIndex = state.roomTypeList.findIndex(
        (room_type_item) => room_type_item.roomId === action.payload.roomId
      );
      if (roomTypeIndex !== -1) {
        state.roomTypeList.splice(roomTypeIndex, 1);
      }
    },
    updateRoomRate: (
      state,
      action: PayloadAction<{ roomTypeId: number; roomRate: number }>
    ) => {
      let _room_type = state.roomTypeList.find(
        (room_type) => room_type.roomId === action.payload.roomTypeId
      );
      let _room_type_index = state.roomTypeList.findIndex(
        (room_type) => room_type.roomId === action.payload.roomTypeId
      );
      if (_room_type !== undefined) {
        state.roomTypeList[_room_type_index] = {
          ..._room_type,
          roomRate: action.payload.roomRate,
        };
      }
    },
    removeAllRoomType: (state) => {
      state.roomTypeList = [];
    },
    updateTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    calculateTotal: (state) => {
      let total = 0;
      state.roomTypeList.forEach(
        (room_type) => (total += room_type.roomRate * state.nights)
      );
      state.total = total;
    },
    updateGuestSearchCriteria: (
      state,
      action: PayloadAction<BookedRoomGuestDetails[]>
    ) => {
      state.searchedGuestCriteria = action.payload;
    },
    updateRoomSearchCriteria: (state, action: PayloadAction<number>) => {
      state.roomsAmount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateReservationCode,
  addBookingDetails,
  addDateRange,
  addRoomType,
  addRoomTypeList,
  removeRoomType,
  updateTotal,
  removeAllRoomType,
  calculateTotal,
  updateRoomRate,
  updateGuestSearchCriteria,
  updateRoomSearchCriteria,
} = bookingSlice.actions;

export default bookingSlice.reducer;
